import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function TextField({
  className,
  handleChange,
  handleInputTypeChange,
  icon,
  inputType,
  name,
  value,
  ...props
}) {
  return (
    <React.Fragment>
      {icon ? <div className="me-2">{icon}</div> : null}
      <div style={{ flex: 1 }}>
        <input
          className={className ? className : "form-control"}
          name={name}
          onChange={handleChange}
          type={inputType}
          value={value}
          {...props}
        />
      </div>
      {name === "password" ? (
        <div className="ms-2">
          <FontAwesomeIcon
            icon={inputType === "password" ? faEye : faEyeSlash}
            onClick={handleInputTypeChange}
            style={{
              cursor: "pointer",
            }}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
}

export default TextField;
