import { faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import CustomButton from "../../common/CustomButton";
import { axiosGet } from "../../utils/AxiosApi";
import { cardType, URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

/**
 * This is a component for Billing history
 *
 * @author Sandeep Shakya
 */

class ResellerBillingHistory extends Component {
  state = {
    loader: false,
    transactions: [],
  };

  componentDidMount() {
    this.getBillingHistory();
  }

  getBillingHistory = () => {
    this.setState({ loader: true, transactions: [] }, function () {
      axiosGet(
        URL.getResellerAllTransaction,
        (response) => {
          if (response.status == 200) {
            this.setState({
              loader: false,
              transactions: response.data.data ? response.data.data.items : [],
            });
          }
        },
        (err) => {
          this.setState({ loader: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  receipt = (item) => {
    let url = item.stripe_receipt_url;
    window.open(url, "_blank");
  };

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col">
              <div className="titleHeaderStyle">Transactions</div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col text-end">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="loginBtn mt-2"
                table="table-to-xls"
                filename="Billing History"
                sheet="billingHistory"
                buttonText="Export as xls"
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col text-center">
              <table
                className="table table-striped mb-0 table-bordered"
                id="table-to-xls"
              >
                <thead>
                  <tr>
                    <th>Transaction ID</th>
                    <th>Advisor Name</th>
                    <th>Description</th>
                    <th>Payment Date</th>
                    <th>Next Payment Date</th>
                    <th>Amount</th>
                    <th>Payment Method</th>
                    <th>Payment Status</th>
                    <th>Invoice ID</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.transactions.length > 0 ? (
                    this.state.transactions.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{item.stripe_payment_intent_id}</td>
                          <td>
                            <>
                              <strong>{item.user.name}</strong>
                            </>
                          </td>
                          <td>{item.invoice.title}</td>
                          <td>
                            {item.invoice.paid_on
                              ? moment(item.invoice.paid_ons).format("ll")
                              : null}
                          </td>
                          <td>
                            {item.invoice.payment_date
                              ? moment(item.invoice.payment_date).format("ll")
                              : null}
                          </td>
                          <td>AUD ${item.invoice.price}</td>
                          <td>
                            {item.stripe.status === "succeeded" ? (
                              <span>
                                {item.stripe.charges.data[0]
                                  .payment_method_details.card.brand ===
                                cardType.visa ? (
                                  <FontAwesomeIcon
                                    icon={faCcVisa}
                                    color="blue"
                                    size="lg"
                                  ></FontAwesomeIcon>
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faCcMastercard}
                                    color="darkred"
                                    size="lg"
                                  ></FontAwesomeIcon>
                                )}{" "}
                                <span style={{ fontSize: "13px" }}>
                                  <strong>
                                    {item.stripe.charges.data[0].payment_method_details.card.brand.toUpperCase()}{" "}
                                    -{" "}
                                    {
                                      item.stripe.charges.data[0]
                                        .payment_method_details.card.last4
                                    }
                                  </strong>
                                </span>
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="text-center">
                            <span
                              className={
                                item.stripe.status === "succeeded"
                                  ? "paidChip"
                                  : "unpaidChip"
                              }
                            >
                              {item.stripe.status === "succeeded"
                                ? "Paid"
                                : "Pending"}
                            </span>
                          </td>
                          <td>
                            {item.invoice.invoice_number
                              ? item.invoice.invoice_number
                              : "-"}
                          </td>
                          <td>
                            <CustomButton
                              text="Receipt"
                              onClick={() => this.receipt(item)}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        {this.state.loader ? (
                          <>
                            <Spinner></Spinner>
                            <br></br>
                            Loading Data...
                          </>
                        ) : (
                          "No Transaction Data"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ResellerBillingHistory;
