import moment from "moment";
import React from "react";
import CustomButton from "../../common/CustomButton";

function UpcomingInvoice(props) {
  return (
    <div className="outstandingBalanceBox mb-4">
      <div className="container-fluid ps-0 pe-0">
        <div className="row">
          <div className="col">
            <strong>Upcoming Invoice</strong>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <table className="table table-striped table-bordered mb-0 text-center">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  {/* <th>Collection Method</th> */}
                  {/* <th>Payment Type</th> */}
                  <th>Last Payment</th>
                  <th>Next Payment</th>
                  <th>Time Remaining</th>
                  <th>Total Amount</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {props.upcomingInvoices.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{item.title}</td>
                      <td>{item.description}</td>
                      {/* <td>{props.upcomingInvoices.collection_method}</td> */}
                      {/* <td>{props.upcomingInvoices.type}</td> */}
                      {/* <td>
                    {moment
                      .unix(props.upcomingInvoices.period_start)
                      .format("LL")}
                  </td> */}
                      <td>
                        {item.paid_on ? moment(item.paid_on).format("LL") : "-"}
                      </td>
                      <td>{moment(item.payment_date).format("LL")}</td>
                      <td>
                        {moment(item.payment_date).diff(moment(), "days")} days
                      </td>
                      <td>AUD ${item.price}</td>
                      <td>
                        <CustomButton
                          text="Details"
                          onClick={() =>
                            props.handleDetailModal(item.invoice_details)
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpcomingInvoice;
