import React from "react";

function PersonalInfo(props) {
  return (
    <table className="table myDetails mb-0">
      <tbody>
        <tr>
          <th>Full Name</th>
          <th width="80px" className="text-center">
            :
          </th>
          <td>{props.name}</td>
        </tr>
        {/* {props.isAdmin ? ( */}
        {/* <tr>
          <th>Username</th>
          <th width="80px" className="text-center">
            :
          </th>
          <td>{props.username}</td>
        </tr> */}
        {/* ) : ( */}
        <tr>
          <th>Address</th>
          <th width="80px" className="text-center">
            :
          </th>
          <td>{props.address}</td>
        </tr>
        {/* )} */}
        <tr>
          <th>Email</th>
          <th width="80px" className="text-center">
            :
          </th>
          <td>{props.email}</td>
        </tr>
        {/* {props.isAdmin ? null : ( */}
        <tr>
          <th>Contact</th>
          <th width="80px" className="text-center">
            :
          </th>
          <td>{props.contact}</td>
        </tr>
        {/* )} */}
      </tbody>
    </table>
  );
}

export default PersonalInfo;
