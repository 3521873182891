import React, { Component } from "react";
import { Spinner } from "reactstrap";
import CustomButton from "../../common/CustomButton";
import CustomModal from "../../common/CustomModal";
import { axiosGet } from "../../utils/AxiosApi";
import { moduleName, URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import { checkRolePermissions } from "../../utils/Helper";
import AssignRoles from "./AssignRoles";
import CreateUserLevel from "./CreateUserLevel";
import PermissionsModal from "./PermissionsModal";

class UserLevels extends Component {
  state = {
    createModal: false,
    edit: false,
    loader: false,
    permissionModal: false,
    permissions: [],
    roleModal: false,
    rolePermissions: [],
    selectedRoleId: "",
    userRoles: [],
    users: [],
  };

  closePermissionModal = () => {
    this.setState({ permissionModal: false, rolePermissions: [] });
  };

  componentDidMount() {
    this.getUserRoles();
    this.getPermissions();
    this.getAllUsers();
  }

  getAllUsers = () => {
    axiosGet(URL.getAllUsers, (res) => {
      if (res.status === 200) {
        this.setState({ users: res.data });
      }
    });
  };

  getPermissions = () => {
    axiosGet(URL.getPermisssions, (res) => {
      if (res.status === 200) {
        let datum = res.data.data.items;
        if (datum.length > 0) {
          datum.forEach((el) => {
            el.action.forEach((ac) => {
              ac.checked = false;
            });
          });
        }
        this.setState({
          permissions: datum,
        });
      }
    });
  };

  getUserRoles = () => {
    this.setState({ loader: true }, function () {
      axiosGet(
        URL.getUserRoles,
        (res) => {
          if (res.status === 200) {
            this.setState({ userRoles: res.data.data.items, loader: false });
          }
        },
        (err) => {
          this.setState({ loader: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleAssignRole = () => {
    this.setState({ roleModal: !this.state.roleModal });
  };

  handleCreateModal = () => {
    this.setState({ createModal: !this.state.createModal, edit: false });
  };

  managePermissions = (role) => {
    this.setState({ selectedRoleId: role.id });
    axiosGet(URL.getUserRolePermission + role.id, (res) => {
      if (res.status === 200) {
        this.setState({
          rolePermissions: res.data.data.items,
          permissionModal: true,
        });
      }
    });
  };

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col">
              <div className="titleHeaderStyle">User Levels</div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          {checkRolePermissions("create", moduleName.userLevel) ? (
            <div className="row mt-2">
              <div className="col">
                <CustomButton
                  text="Assign Roles"
                  className="ms-2"
                  onClick={this.handleAssignRole}
                />
              </div>
              <div className="col text-end">
                <CustomButton
                  onClick={this.handleCreateModal}
                  text="Add User Level"
                />
              </div>
            </div>
          ) : null}
          <div className="row mt-2">
            <div className="col-md-2"></div>
            <div className="col">
              <table className="table table-striped table-bordered text-center">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.userRoles.length > 0 ? (
                    this.state.userRoles.map((role, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{role.name}</td>
                          <td>
                            <CustomButton
                              text="Permissions"
                              onClick={() => this.managePermissions(role)}
                            ></CustomButton>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={2}>
                        {this.state.loader ? (
                          <>
                            <Spinner></Spinner>
                            <br></br>Loading Data...
                          </>
                        ) : (
                          "No User Levels"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-2"></div>
          </div>

          <CustomModal
            modal={this.state.createModal}
            modalBody={
              <CreateUserLevel
                edit={this.state.edit}
                handleCreateModal={this.handleCreateModal}
                getUserRoles={this.getUserRoles}
                permissions={this.state.permissions}
              />
            }
            modalHeader={this.state.edit ? "Edit User Level" : "Add User level"}
            size="lg"
            toggleModal={this.handleCreateModal}
          ></CustomModal>

          <CustomModal
            modal={this.state.permissionModal}
            modalBody={
              <PermissionsModal
                permissions={this.state.permissions}
                rolePermissions={this.state.rolePermissions}
                selectedRoleId={this.state.selectedRoleId}
                closePermissionModal={this.closePermissionModal}
              />
            }
            modalHeader="Permissions"
            size="lg"
            toggleModal={this.closePermissionModal}
          ></CustomModal>

          <CustomModal
            modal={this.state.roleModal}
            modalBody={
              <AssignRoles
                // handleAssignRole={this.handleAssignRole}
                selectedRoleId={this.state.selectedRoleId}
                userRoles={this.state.userRoles}
                users={this.state.users}
              ></AssignRoles>
            }
            modalHeader="Assign Roles"
            toggleModal={this.handleAssignRole}
          />
        </div>
      </>
    );
  }
}

export default UserLevels;
