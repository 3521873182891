import { serverURL, encrypt_key } from "./Config";

//Define URL and constants here
export const headers = {
  Authorization: `Bearer ${getAccessToken()}`,
};

export function getAccessToken() {
  return localStorage.getItem("token");
}

// export const serverLocation = "http://localhost/odee-api/public",
// export const serverLocation = "https://odee-backend.techarttrekkies.com",
// export const serverLocation = "https://apiodee.iconhost.com.au/odee-api/public",
export const serverLocation = serverURL,
  cryptoSecretKey = encrypt_key,
  fileUrl = serverLocation + "/storage",
  loginUrl = `${serverLocation}/api/login`,
  URL = {
    getMyInformation: `${serverLocation}/api/my-information`,

    getAllReseller: `${serverLocation}/api/resellers/index`,
    getAllResellersAdvisors: `${serverLocation}/api/resellers/getAllAdvisors`,
    getResellerInfo: `${serverLocation}/api/resellers/show`,
    getSingleReseller: `${serverLocation}/api/resellers/show`,
    insertReseller: `${serverLocation}/api/resellers/store`,
    updateReseller: `${serverLocation}/api/resellers/update`,

    getAdvisorInfo: `${serverLocation}/api/advisors/show`,
    getAdvisorRegistrationBill: `${serverLocation}/api/advisors/advisor-registration-bill`,
    getAllAdvisor: `${serverLocation}/api/advisors/index`,
    getSingleAdvisor: `${serverLocation}/api/advisors/show`,
    insertAdvisor: `${serverLocation}/api/advisors/store`,
    payAdvisorRegistrationBill: `${serverLocation}/api/advisors/pay-advisor-registration-bill`,
    updateAdvisor: `${serverLocation}/api/advisors/update`,

    createBillingInformation: `${serverLocation}/api/billing-information/store`,
    getBillingInformation: `${serverLocation}/api/billing-information/index/`,

    createBillingMethod: `${serverLocation}/api/advisors/store-payment-methods`,
    deleteBillingMethod: `${serverLocation}/api/advisors/detach-advisor-billing-method/`,
    getBillingMethod: `${serverLocation}/api/advisors/advisor-billing-methods`,
    setDefaultBillingMethod: `${serverLocation}/api/advisors/set-default-payment-method/`,

    getSystemStatus: `${serverLocation}/api/advisors/is-first-login-and-has-paid-info`,

    getAdvisorAllTransaction: `${serverLocation}/api/advisors/advisor-all-payments`,
    getAdvisorLatestTransaction: `${serverLocation}/api/advisors/advisor-latest-payments`,
    getResellerAllTransaction: `${serverLocation}/api/resellers/all-payments`,

    // getAllInvoices: `${serverLocation}/api/advisors/invoices-including-pending`,
    getUpcomingInvoices: `${serverLocation}/api/advisors/upcoming-invoives`,
    getAllInvoices: `${serverLocation}/api/invoices/getInvoice`,

    getClientInfo: `${serverLocation}/api/clients/show`,
    getAllResellerAdvisor: `${serverLocation}/api/clients/getResellerAdvisor/`,
    getClients: `${serverLocation}/api/clients/index`,
    insertClients: `${serverLocation}/api/clients/store`,
    deleteClient: `${serverLocation}/api/clients/delete/`,
    updateClient: `${serverLocation}/api/clients/update`,

    getAllProducts: `${serverLocation}/api/products/index`,
    storeProduct: `${serverLocation}/api/products/store`,
    subscribeProduct: `${serverLocation}/api/advisors/subscribe-product`,
    getParentProducts: `${serverLocation}/api/products/parentProducts`,

    deleteRootUser: `${serverLocation}/api/root-users/delete`,
    getRootUserInfo: `${serverLocation}/api/root-users/show`,
    rootUsers: `${serverLocation}/api/root-users`,
    updateRootUser: `${serverLocation}/api/root-users/update`,

    deleteAdmin: `${serverLocation}/api/admins`,
    getAdminInfo: `${serverLocation}/api/admins/show`,
    getAdmins: `${serverLocation}/api/admins/index`,
    insertAdmin: `${serverLocation}/api/admins/store`,
    updateAdmin: `${serverLocation}/api/admins/update`,

    getCities: `${serverLocation}/api/cities/`,
    getCountries: `${serverLocation}/api/countries`,
    getCurrencies: `${serverLocation}/api/currencies`,

    userLevels: `${serverLocation}/api/user-levels`,
    getPermisssions: `${serverLocation}/api/user-levels/get-module-permissions`,
    assignPermissionToRole: `${serverLocation}/api/user-levels/assign-permission-to-role`,
    getUserRoles: `${serverLocation}/api/user-levels/get-roles`,
    getUserRolePermission: `${serverLocation}/api/user-levels/get-role-permission/`,
    getAllUsers: `${serverLocation}/api/users/get-all-users`,
    assignRoleToUsers: `${serverLocation}/api/user-levels/assign-role-to-user`,
    getUsersRole: `${serverLocation}/api/user-levels/get-user-roles/`,
    revokeRolesFromUser: `${serverLocation}/api/user-levels/revoke-roles-from-user`,

    getAllTiers: `${serverLocation}/api/tiers/index`,
    getResellersTiers: `${serverLocation}/api/tiers/get-reseller-tier`,
    insertResellersTiers: `${serverLocation}/api/tiers/store-reseller-tier`,
    // updateTier: `${serverLocation}/api/tiers/update-reseller-tier`,
    updateTier: `${serverLocation}/api/resellers/updateTier`,
    deleteTier: `${serverLocation}/api/tiers/delete-reseller-tier/`,

    //Delete APIs
    deleteAdvisor: `${serverLocation}/api/advisors/`,
    deleteReseller: `${serverLocation}/api/resellers/`,
    getResellerProductForClients: `${serverLocation}/api/resellers/recurring-products`,
    getPaymentIntent: `${serverLocation}/api/payment-intents/getPaymentIntent`,
    storePaymentMethodFromSystem: `${serverLocation}/api/store-only-payment-metod`,
    getAuthUserDetails: `${serverLocation}/api/get-auth-user-details`,
    storeClientPaymentMethod: `${serverLocation}/api/clients/store-client-payment-method`,
  };

export const moduleName = {
  admin: "Admin",
  advisor: "Advisor",
  billingHistory: "Billing History",
  billingMethod: "Billing Method",
  commission: "Commission",
  customer: "Customer",
  myDetails: "My Details",
  product: "Product",
  reseller: "Reseller",
  rootUser: "RootUser",
  tier: "Tier",
  transaction: "Transaction",
  userLevel: "UserLevel",
  invoice: "Invoice",
};

export const userRole = {
  admin: "admin",
  advisor: "advisor",
  reseller: "reseller",
  rootUser: "rootuser",
  client: "client",
};

export const cardType = {
  masterCard: "mastercard",
  visa: "visa",
};
