import React, { Component } from "react";
import { Spinner } from "reactstrap";
import CustomModal from "../../common/CustomModal";
import CustomButton from "../../common/CustomButton";
import { fileUrl, moduleName, URL } from "../../utils/Constants";
import { axiosDelete, axiosGet } from "../../utils/AxiosApi";
import { checkRolePermissions } from "../../utils/Helper";
import CreateRootUser from "./CreateRootUser";
import swal from "sweetalert";
import { displayErrorAlert, DISPLAYMESSAGE } from "../../utils/DisplayMessage";

class RootUsers extends Component {
  state = {
    rootUsers: [],
    action: "",
    createModal: false,
    dataSpinner: false,
    deletePermission: false,
    edit: false,
    editPermission: false,
    selectedData: {},
    selectedId: "",
  };

  componentDidMount() {
    this.permissions();
  }

  getRootUsers = () => {
    this.setState({ dataSpinner: true }, function () {
      axiosGet(
        URL.rootUsers,
        (response) => {
          if (response.status === 200) {
            this.setState({
              rootUsers: response.data.data.items,
              dataSpinner: false,
            });
          }
        },
        (err) => {
          this.setState({ dataSpinner: false });
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({ createModal: !this.state.createModal, edit: false });
  };

  handleDelete = (item) => {
    axiosDelete(
      URL.deleteRootUser + "/" + item.id,
      (response) => {
        if (response.status === 200) {
          swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
          this.getRootUsers();
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  handleEdit = (item) => {
    this.setState({ selectedData: item, edit: true, createModal: true });
  };

  permissions = () => {
    this.setState(
      {
        editPermission: checkRolePermissions("update", moduleName.rootUser),
        deletePermission: checkRolePermissions("delete", moduleName.rootUser),
      },
      function () {
        this.getRootUsers();
      }
    );
  };

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col">
              <div className="titleHeaderStyle">Root Users</div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          {checkRolePermissions("create", moduleName.rootUser) ? (
            <div className="row mt-2">
              <div className="col text-end">
                <CustomButton
                  text="Add Root User"
                  onClick={this.handleCreateModal}
                />
              </div>
            </div>
          ) : null}
          <div className="row mt-2">
            <div className="col">
              <table className="table table-striped">
                <thead>
                  <tr>
                    {/* <th>Photo</th> */}
                    <th>Name</th>
                    {/* <th>Username</th> */}
                    <th>Address</th>
                    <th>Contact Details</th>
                    <th>Email</th>
                    {this.state.editPermission ||
                    this.state.deletePermission ? (
                      <th>Option</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {this.state.rootUsers.length > 0 ? (
                    this.state.rootUsers.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          {/* <td>
                            {item.media && item.media.length > 0 ? (
                              <img
                                src={
                                  fileUrl +
                                  "/" +
                                  item.media[0].id +
                                  "/" +
                                  item.media[0].file_name
                                }
                                className="logoDisplay"
                              />
                            ) : null}
                          </td> */}
                          <td>
                            {item.user.first_name} {item.user.last_name}
                          </td>
                          {/* <td>{item.user.username}</td> */}
                          <td>{item.user.address}</td>
                          <td>{item.user.contact}</td>
                          <td>{item.user.email}</td>
                          {this.state.editPermission ||
                          this.state.deletePermission ? (
                            <td>
                              {this.state.editPermission ? (
                                <CustomButton
                                  className="me-2"
                                  onClick={() => this.handleEdit(item)}
                                  text="Edit"
                                ></CustomButton>
                              ) : null}
                              {this.state.deletePermission ? (
                                <CustomButton
                                  className="cancelBtn"
                                  onClick={() => this.handleDelete(item)}
                                  text="Delete"
                                ></CustomButton>
                              ) : null}
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={
                          this.state.editPermission ||
                          this.state.deletePermission
                            ? 5
                            : 4
                        }
                        className="text-center p-3"
                      >
                        {this.state.dataSpinner ? (
                          <>
                            <Spinner color="primary"></Spinner>
                            <br />
                            Loading Data...
                          </>
                        ) : (
                          "No Root Users added yet"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <CustomModal
            modal={this.state.createModal}
            modalBody={
              <CreateRootUser
                edit={this.state.edit}
                getRootUsers={this.getRootUsers}
                handleCreateModal={this.handleCreateModal}
                selectedData={this.state.selectedData}
              />
            }
            modalHeader={this.state.edit ? "Edit Root User" : "Add Root User"}
            size="lg"
            toggleModal={this.handleCreateModal}
          ></CustomModal>
        </div>
      </>
    );
  }
}

export default RootUsers;
