import React from "react";
import { Spinner } from "reactstrap";
import CustomButton from "../../common/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

function CurrentInvoices(props) {
  return (
    <div className="outstandingBalanceBox">
      <div className="container-fluid ps-0 pe-0">
        <div className="row">
          <div className="col">
            <strong>Current Invoices</strong>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <table className="table table-striped table-bordered mb-0 text-center">
              <thead>
                <tr>
                  <th>Invoice Id</th>
                  <th>Description</th>
                  {/* <th>Collection Method</th> */}
                  {/* <th>Payment Type</th> */}
                  <th>Payment Date</th>
                  {/* <th>Next Payment Date</th> */}
                  <th>Payment Status</th>
                  <th>Total Amount</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {props.invoices.length > 0 ? (
                  props.invoices
                    .filter((el) => el.total !== 0)
                    .map((invoice, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{invoice.invoice_number}</td>
                          <td>{invoice.title}</td>
                          {/* <td>{invoice.collection_method}</td> */}
                          <td>
                            {invoice.paid_on
                              ? moment(invoice.paid_on).format("ll")
                              : "-"}
                          </td>
                          {/* <td>
                            {invoice.payment_date
                              ? moment(invoice.payment_date).format("ll")
                              : null}
                          </td> */}
                          <td>
                            <span
                              className={
                                invoice.status === "paid"
                                  ? "paidChip"
                                  : "unpaidChip"
                              }
                            >
                              {invoice.status === "paid" ? "Paid" : "Pending"}
                            </span>
                          </td>
                          <td>AUD ${invoice.price}</td>
                          <td>
                            <CustomButton
                              text="Details"
                              onClick={() =>
                                props.handleDetailModal(invoice.invoice_items)
                              }
                            />
                          </td>
                          <td>
                            {invoice.file_url ? (
                              <div
                                onClick={() => props.downloadInvoice(invoice)}
                              >
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  size="lg"
                                  className="faDownload"
                                />
                              </div>
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td colSpan={8}>
                      {props.invoiceSpinner ? (
                        <>
                          <Spinner></Spinner>
                          <br></br>Loading Data...
                        </>
                      ) : (
                        "No Invoices"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CurrentInvoices;
