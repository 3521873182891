import React, { useState } from "react";
import ReactImageMagnify from "react-image-magnify";
import CustomButton from "../../common/CustomButton";
import { fileUrl } from "../../utils/Constants";
import product from "../../assets/images/download.png";

function ProductDetailModal(props) {
  const [preview, setPreview] = useState("");

  const previewImage = (idx) => {
    let photos = [...props.selectedData.media];
    let previewData =
      fileUrl + "/" + photos[idx].id + "/" + photos[idx].file_name;
    setPreview(previewData);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-5">
          {props.selectedData.media && props.selectedData.media.length > 0 ? (
            <>
              {/* <div
                className="productPreview mt-3 mb-3"
                style={{ height: "250px" }}
              > */}
              <ReactImageMagnify
                {...{
                  smallImage: {
                    isFluidWidth: true,
                    src:
                      preview === ""
                        ? fileUrl +
                          "/" +
                          props.selectedData.media[0].id +
                          "/" +
                          props.selectedData.media[0].file_name
                        : preview,
                  },
                  largeImage: {
                    src:
                      preview === ""
                        ? fileUrl +
                          "/" +
                          props.selectedData.media[0].id +
                          "/" +
                          props.selectedData.media[0].file_name
                        : preview,
                    width: 900,
                    height: 900,
                  },
                  enlargedImagePortalId: "portal",
                  enlargedImageContainerDimensions: {
                    width: 480,
                    height: 350,
                  },
                  hoverDelayInMs: 50,
                  className: "productPreview mt-3 mb-3 productPreviewHeight",
                }}
              />
              {/* </div> */}

              <div className="productThumbnailHolder mt-3">
                {props.selectedData.media.map((photo, idx) => {
                  return (
                    <div
                      className="productThumbnail"
                      key={idx}
                      onClick={() => previewImage(idx)}
                    >
                      <img
                        src={fileUrl + "/" + photo.id + "/" + photo.file_name}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="productPreview" style={{ height: "250px" }}>
              <img src={product} />
            </div>
          )}
        </div>
        <div className="col-md-7" style={{ position: "relative" }}>
          <div id="portal" className="portal magnifiedImage" />
          <div
            style={{
              maxHeight: "76vh",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <h3 className="productDetailTitle">{props.selectedData.name}</h3>
            <div className="line"></div>
            <div className="mb-3 productType">
              <strong>Product Type: </strong>
              {props.selectedData.type}
            </div>
            <h6 className="productDetailDescription">
              {props.selectedData.description}
            </h6>
            <div className="productPrice">
              AUD $
              <span style={{ fontSize: "45px" }}>
                {props.selectedData.base_price}
              </span>
            </div>

            <div className="row mt-4">
              <div className="col text-end">
                <CustomButton
                  text="Buy"
                  className="cancelBtn me-2"
                  onClick={() => props.handleSubscribeBtn(props.selectedData)}
                />
                {/* {props.selectedData.is_subscribed ? (
                  <span className="me-2" style={{ fontSize: "12px" }}>
                    Subscribed
                  </span>
                ) : (
                  <CustomButton
                    text="Subscribe"
                    className="cancelBtn me-2"
                    onClick={() => props.handleSubscribeBtn(props.selectedData)}
                  />
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetailModal;
