import React, { Component } from "react";
import CustomButton from "../../common/CustomButton";
import CustomModal from "../../common/CustomModal";
import { moduleName, URL, userRole } from "../../utils/Constants";
import { checkRolePermissions, checkUserRoles } from "../../utils/Helper";
import CreateProductsServices from "./CreateProductsServices";
import ProductDetailModal from "./ProductDetailModal";
import ProductList from "./ProductList";
import ProductListForResellers from "./ProductListForResellers";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import SubscribeModal from "./SubscribeModal";
import { displayErrorAlert, DISPLAYMESSAGE } from "../../utils/DisplayMessage";
import swal from "sweetalert";
import CustomFullSpinner from "../../common/CustomFullSpinner";

class ProductsServices extends Component {
  state = {
    createModal: false,
    detailModal: false,
    loader: false,
    productData: "",
    products: [],
    selectedData: {},
    spinner: false,
    subscribeModal: false,
    isAdvisor: checkUserRoles(userRole.advisor),
    isClient: checkUserRoles(userRole.client),
  };

  closeDetailModal = () => {
    this.setState({
      detailModal: !this.state.detailModal,
    });
  };

  closeSubscribeBtn = () => {
    this.setState({
      subscribeModal: !this.state.subscribeModal,
      productData: "",
    });
  };

  componentDidMount() {
    this.getAllProducts();
  }

  getAllProducts = () => {
    this.setState({ loader: true, products: [] }, function () {
      axiosGet(
        this.state.isAdvisor || this.state.isClient
          ? URL.getParentProducts
          : URL.getAllProducts,
        (response) => {
          if (response.status === 200) {
            this.setState({
              products: response.data.data.items,
              loader: false,
            });
          }
        },
        (err) => {
          this.setState({ loader: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({ createModal: !this.state.createModal });
  };

  handleDetailModal = (item) => {
    this.setState({ detailModal: !this.state.detailModal, selectedData: item });
  };

  handleSave = (param) => {
    let formData = new FormData(),
      product = {
        name: param.name,
        description: param.description,
        type: param.type,
        base_price: param.unitAmount,
        status: param.status,
        end_user_tier_product: param.end_user_tier_product,
        variants: [],
      };
    /* Not doing anything. It is commented out. */
    formData.append("product", JSON.stringify(product));
    // formData.append("price", JSON.stringify(price));
    if (param.photoFiles.length > 0) {
      param.photoFiles.forEach((el) => {
        formData.append("files[]", el);
      });
    }
    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.storeProduct,
        formData,
        (res) => {
          if (res.status === 200) {
            swal("Success", DISPLAYMESSAGE.insertSuccess, "success");
            this.setState({ spinner: false });
            this.handleCreateModal();
            this.getAllProducts();
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleSubscribeBtn = (item) => {
    this.setState({
      subscribeModal: !this.state.subscribeModal,
      productData: item,
    });
  };

  render() {
    return (
      <>
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col">
              <div className="titleHeaderStyle">Products and Services</div>
            </div>
          </div>
        </div>
        {this.state.isAdvisor || this.state.isClient ? (
          <div className="container">
            <div className="row mt-2">
              <div className="col">
                <ProductList
                  loader={this.state.loader}
                  products={this.state.products}
                  handleDetailModal={this.handleDetailModal}
                  handleSubscribeBtn={this.handleSubscribeBtn}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row mt-2">
              <div className="col text-end">
                {checkRolePermissions("create", moduleName.product) ? (
                  <CustomButton
                    text="Add Products/Services"
                    onClick={this.handleCreateModal}
                  />
                ) : null}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <ProductListForResellers
                  products={this.state.products}
                  loader={this.state.loader}
                />
              </div>
            </div>
          </div>
        )}

        <CustomModal
          modal={this.state.createModal}
          modalBody={<CreateProductsServices handleSave={this.handleSave} />}
          modalHeader="Create Products or Services"
          size="lg"
          toggleModal={this.handleCreateModal}
        ></CustomModal>
        <CustomModal
          modal={this.state.detailModal}
          modalBody={
            <ProductDetailModal
              selectedData={this.state.selectedData}
              handleSubscribeBtn={this.handleSubscribeBtn}
            />
          }
          modalHeader="Product Details"
          size="xl"
          toggleModal={this.closeDetailModal}
        />
        <CustomModal
          modal={this.state.subscribeModal}
          modalBody={
            <SubscribeModal
              closeSubscribeBtn={this.closeSubscribeBtn}
              productData={this.state.productData}
              getAllProducts={this.getAllProducts}
            />
          }
          modalHeader="Buy Product"
          toggleModal={this.closeSubscribeBtn}
        ></CustomModal>
      </>
    );
  }
}

export default ProductsServices;
