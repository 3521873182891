/**
 * This is main page of the application
 *
 *
 * @author Sandeep Shakya
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  faCogs,
  faFileInvoiceDollar,
  faGem,
  faIdCard,
  faKey,
  faLayerGroup,
  faMoneyCheckAlt,
  faReceipt,
  faShoppingCart,
  faSignOutAlt,
  faUserCircle,
  faUserFriends,
  faUsers,
  faUserShield,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Sidebar from "./Sidebar";
import SidebarRoutes from "../routes/SidebarRoutes";
import { checkRolePermissions, checkUserRoles } from "../utils/Helper";
import { moduleName, userRole } from "../utils/Constants";
import {
  setIsFirstLogin,
  setIsSubscribed,
  getAdvisorStatus,
} from "../ducks/LoginDucks";

class Main extends Component {
  state = {
    loader: false,
    menus: [
      {
        icon: <FontAwesomeIcon icon={faGem} />,
        menuName: "Dashboard",
        path: "/dashboard/",
        display: true,
      },
      {
        icon: <FontAwesomeIcon icon={faUserShield} />,
        menuName: "Root Users",
        path: "/dashboard/root-users",
        display: checkRolePermissions("view", moduleName.rootUser),
      },
      {
        icon: <FontAwesomeIcon icon={faUserCircle} />,
        menuName: "Admins",
        path: "/dashboard/admins",
        display: checkRolePermissions("view", moduleName.admin),
      },
      {
        icon: <FontAwesomeIcon icon={faUserTie} />,
        menuName: "Resellers",
        path: "/dashboard/resellers",
        display: checkRolePermissions("view", moduleName.reseller),
      },
      {
        icon: <FontAwesomeIcon icon={faUserFriends} />,
        menuName: "Advisors",
        path: "/dashboard/advisors",
        display: checkRolePermissions("view", moduleName.advisor),
      },
      {
        icon: <FontAwesomeIcon icon={faIdCard} />,
        menuName: "My Details",
        path: "/dashboard/my-details",
        display:
          checkRolePermissions("myDetails", moduleName.reseller) ||
          checkRolePermissions("myDetails", moduleName.advisor) ||
          checkRolePermissions("myDetails", moduleName.customer) ||
          checkRolePermissions("myDetails", moduleName.admin) ||
          checkRolePermissions("myDetails", moduleName.rootUser),
      },
      {
        icon: <FontAwesomeIcon icon={faShoppingCart} />,
        menuName: "Products and Services",
        path: "/dashboard/products-services",
        display: checkRolePermissions("view", moduleName.product),
      },
      {
        icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
        menuName: "Billing Method",
        path: "/dashboard/billing-method",
        display: checkRolePermissions("view", moduleName.billingMethod),
      },
      {
        icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
        menuName: "Transactions",
        path: "/dashboard/transactions",
        display: checkRolePermissions("view", moduleName.transaction),
      },
      {
        icon: <FontAwesomeIcon icon={faReceipt} />,
        menuName: "Billing History",
        path: "/dashboard/billing-history",
        display: checkRolePermissions("view", moduleName.billingHistory),
      },
      {
        icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
        menuName: "Invoices",
        path: "/dashboard/invoices",
        display: checkRolePermissions("view", moduleName.invoice),
      },
      {
        icon: <FontAwesomeIcon icon={faCogs} />,
        menuName: "End User Subscription Fee",
        path: "/dashboard/tiers",
        display: checkRolePermissions("view", moduleName.tier),
      },
      {
        icon: <FontAwesomeIcon icon={faKey} />,
        menuName: "API Keys",
        path: "/dashboard/apiKeys",
        display:
          checkRolePermissions("viewApiKey", moduleName.reseller) ||
          checkRolePermissions("viewApiKey", moduleName.advisor) ||
          checkRolePermissions("viewApiKey", moduleName.customer) ||
          checkRolePermissions("viewApiKey", moduleName.rootUser) ||
          checkRolePermissions("viewApiKey", moduleName.admin),
      },
      {
        icon: <FontAwesomeIcon icon={faMoneyCheckAlt} />,
        menuName: "Commissions",
        path: "/dashboard/commissions",
        display: checkRolePermissions("view", moduleName.commission),
      },
      {
        icon: <FontAwesomeIcon icon={faUsers} />,
        menuName: "Manage Clients",
        path: "/dashboard/clients",
        display: checkRolePermissions("view", moduleName.customer),
      },
      {
        icon: <FontAwesomeIcon icon={faLayerGroup} />,
        menuName: "User Levels",
        path: "/dashboard/user-levels",
        display: checkRolePermissions("view", moduleName.userLevel),
      },
      {
        icon: <FontAwesomeIcon icon={faSignOutAlt} />,
        menuName: "Log Out",
        path: "/login",
        display: true,
      },
    ],
    newMenu: [],
  };

  componentDidMount() {
    this.permissions();
  }

  permissions = () => {
    if (checkUserRoles(userRole.advisor)) {
      this.props.getAdvisorStatus();
    } else if (checkUserRoles(userRole.client)) {
      this.props.getAdvisorStatus();
    } else {
      this.props.setIsSubscribed(true);
      this.props.setIsFirstLogin(false);
    }
  };

  render() {
    return (
      <div style={{ display: "flex", position: "relative" }}>
        <Sidebar
          loader={this.props.loader}
          menus={
            this.props.loader
              ? []
              : this.props.isFirstLogin || !this.props.isSubscribed
              ? [
                  this.state.menus[0],
                  this.state.menus[this.state.menus.length - 1],
                ]
              : this.state.menus
          }
        />

        <SidebarRoutes />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isFirstLogin: state.login.isFirstLogin,
  isSubscribed: state.login.isSubscribed,
  loader: state.login.loader,
});

const mapActionsToProps = {
  setIsFirstLogin,
  setIsSubscribed,
  getAdvisorStatus,
};

export default connect(mapStateToProps, mapActionsToProps)(Main);
