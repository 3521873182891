import React, { Component } from "react";
import { axiosGet } from "../../utils/AxiosApi";
import { serverLocation, URL } from "../../utils/Constants";
import CustomModal from "../../common/CustomModal";
import UpcomingInvoiceDetails from "./UpcomingInvoiceDetails";
import UpcomingInvoice from "./UpcomingInvoice";
import CurrentInvoices from "./CurrentInvoices";

class Invoices extends Component {
  state = {
    detailModal: false,
    invoiceDetails: [],
    invoices: [],
    invoiceSpinner: false,
    upcomingSpinner: false,
    upcomingInvoices: null,
  };

  componentDidMount() {
    this.getAllInvoices();
    this.getUpcomingInvoices();
  }

  downloadInvoice = (invoice) => {
    // var url = invoice.file_url;
    var url = serverLocation + "/download-invoice/" + invoice.invoice_number;
    window.open(url, "_blank");
  };

  getAllInvoices = () => {
    this.setState({ invoiceSpinner: true }, function () {
      axiosGet(
        URL.getAllInvoices,
        (response) => {
          if (response.status === 200) {
            this.setState({
              invoices: response.data.data.items,
              invoiceSpinner: false,
            });
          }
        },
        function () {
          this.setState({ invoiceSpinner: false });
        }
      );
    });
  };

  getUpcomingInvoices = () => {
    this.setState({ upcomingSpinner: true }, function () {
      axiosGet(
        URL.getUpcomingInvoices,
        (response) => {
          if (response.status === 200) {
            this.setState({
              upcomingInvoices: response.data.data.items,
              upcomingSpinner: false,
            });
          }
        },
        (err) => {
          this.setState({ upcomingSpinner: false });
        }
      );
    });
  };

  handleDetailModal = (details) => {
    if (details) {
      this.setState({ invoiceDetails: details });
    }
    this.setState({ detailModal: !this.state.detailModal });
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "#f4f5f7",
          height: "100%",
        }}
      >
        <div className="container-fluid">
          <div className="row pt-2">
            <div className="col">
              <div className="titleHeaderStyle">Invoices</div>
            </div>
          </div>
        </div>
        {this.state.upcomingInvoices &&
        this.state.upcomingInvoices.length > 0 ? (
          <UpcomingInvoice
            handleDetailModal={this.handleDetailModal}
            upcomingInvoices={this.state.upcomingInvoices}
            upcomingSpinner={this.state.upcomingSpinner}
          />
        ) : null}
        <CurrentInvoices
          downloadInvoice={this.downloadInvoice}
          handleDetailModal={this.handleDetailModal}
          invoices={this.state.invoices}
          invoiceSpinner={this.state.invoiceSpinner}
        />
        <CustomModal
          modal={this.state.detailModal}
          modalBody={
            <UpcomingInvoiceDetails datas={this.state.invoiceDetails} />
          }
          size="xl"
          modalHeader="Invoice Details"
          toggleModal={this.handleDetailModal}
        />
      </div>
    );
  }
}

export default Invoices;
