import React, { Component } from "react";
import swal from "sweetalert";
import CustomButton from "../../common/CustomButton";
import CustomFullSpinner from "../../common/CustomFullSpinner";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";

class CreateUserLevel extends Component {
  state = {
    userLevelName: "",
    permissions: [],
    tempPermissions: [],
    spinner: false,
  };

  componentDidMount() {
    // this.getPermissions();
    this.config();
  }

  config = () => {
    if (this.props.permissions && this.props.permissions.length > 0) {
      this.setState({
        permissions: JSON.parse(JSON.stringify(this.props.permissions)),
        tempPermissions: JSON.parse(JSON.stringify(this.props.permissions)),
      });
    }
  };

  // getPermissions = () => {
  //   axiosGet(URL.getPermisssions, (res) => {
  //     if (res.status === 200) {
  //       let datum = res.data.data.items;
  //       if (datum.length > 0) {
  //         datum.forEach((el) => {
  //           el.action.forEach((ac) => {
  //             ac.checked = false;
  //           });
  //         });
  //       }
  //       this.setState({
  //         permissions: datum,
  //         tempPermissions: JSON.parse(JSON.stringify(datum)),
  //       });
  //     }
  //   });
  // };

  handleActionCheckAll = (e, permissionIdx) => {
    let permissions = [...this.state.permissions];
    if (e.target.checked) {
      permissions[permissionIdx].action.forEach((el) => {
        el.checked = true;
      });
    } else {
      permissions[permissionIdx].action.forEach((el) => {
        el.checked = false;
      });
    }
    this.setState({ permissions });
  };

  handleCancel = () => {
    this.setState({
      userLevelName: "",
      permissions: JSON.parse(JSON.stringify(this.state.tempPermissions)),
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.type === "checked" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  handleCheckbox = (e, permissionIdx, actionIdx) => {
    let permissions = [...this.state.permissions];
    permissions[permissionIdx].action[actionIdx].checked = e.target.checked;
    this.setState({ permissions });
  };

  handleSave = () => {
    let allPermissions = [...this.state.permissions],
      permissions = [],
      checkedPermissions = 0;

    if (allPermissions.length > 0) {
      allPermissions.forEach((el) => {
        el.action.forEach((ac) => {
          if (ac.checked) {
            checkedPermissions++;
            permissions.push(ac.id);
          }
        });
      });
    }
    if (this.state.userLevelName === "") {
      swal("Error", "User level name should not be empty", "error");
      return false;
    }
    if (checkedPermissions === 0) {
      swal("Error", "At least give one permission to this user level", "error");
      return false;
    }
    let param = {
      user_level: this.state.userLevelName,
      permissions: permissions,
    };

    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.userLevels,
        param,
        (res) => {
          if (res.status === 200) {
            swal("Success", "User Level successfully created", "success");
            this.props.getUserRoles();
            this.props.handleCreateModal();
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  render() {
    return (
      <div className="container">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="row">
          <div className="col-md-3 mt-1">
            <strong>User Level Name </strong>
          </div>
          <div className="col-md-9">
            <input
              type="text"
              className="form-control"
              name="userLevelName"
              value={this.state.userLevelName}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="text-center permissionHeader">
              <strong>Select Permissions</strong>
            </div>
            <div className="userLevelPermissionBox">
              {this.state.permissions.map((permission, idx) => {
                return (
                  <table className="table table-bordered " key={idx}>
                    <thead className="table-primary">
                      <tr>
                        <th colSpan={permission.action.length - 1}>
                          {permission.moduleName}
                        </th>
                        <th className="text-center">
                          <input
                            id={permission.moduleName + idx}
                            type="checkbox"
                            className="form-check-input"
                            onChange={(e) => this.handleActionCheckAll(e, idx)}
                          ></input>
                          <label
                            htmlFor={permission.moduleName + idx}
                            className="ms-2"
                          >
                            Check All
                          </label>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      <tr>
                        {permission.action.map((act, aIdx) => {
                          return (
                            <td key={aIdx}>
                              <input
                                id={permission.moduleName + aIdx}
                                type="checkbox"
                                className="form-check-input"
                                name="checkbox"
                                checked={act.checked}
                                onChange={(e) =>
                                  this.handleCheckbox(e, idx, aIdx)
                                }
                              ></input>
                              <label
                                htmlFor={permission.moduleName + aIdx}
                                className="ms-2"
                              >
                                {act.name}
                              </label>
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                );
              })}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            <CustomButton text="Save" onClick={this.handleSave} />
            <CustomButton
              text="Cancel"
              className="cancelBtn ms-2"
              onClick={this.handleCancel}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CreateUserLevel;
