import React, { Component } from "react";
import swal from "sweetalert";
import CustomButton from "../../common/CustomButton";
import CustomFullSpinner from "../../common/CustomFullSpinner";
import { axiosPost } from "../../utils/AxiosApi";
import { fileUrl, URL as URLs } from "../../utils/Constants";
import { displayErrorAlert, DISPLAYMESSAGE } from "../../utils/DisplayMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

class CreateReseller extends Component {
  state = {
    address: "",
    brandDisplayName: "",
    brandLogo: "",
    brandLogoFile: "",
    brandLogoPreview: "",
    brandPrimaryColor: "",
    brandSecondaryColor: "",
    brandUrl: "",
    contact: "",
    email: "",
    error: {},
    favIcon: "",
    favIconFile: "",
    favIconPreview: "",
    hasExistingSystem: false,
    id: "",
    imagePreview: "",
    name: "",
    firstName: "",
    lastName: "",
    password: "",
    photo: "",
    photoFile: "",
    spinner: false,
    username: "",
  };

  componentDidMount() {
    if (this.props.edit) {
      this.editConfig();
    }
  }

  editConfig = () => {
    let selectedData = this.props.selectedData;
    this.setState(
      {
        // name: selectedData.user.name,
        firstName: selectedData.user.first_name,
        lastName: selectedData.user.last_name,
        address: selectedData.user.address,
        email: selectedData.user.email,
        contact: selectedData.user.contact,
        username: selectedData.user.username,
        brandDisplayName: selectedData.brand_name,
        brandPrimaryColor: selectedData.primary_color,
        brandSecondaryColor: selectedData.secondary_color,
        brandUrl: selectedData.odee_url,
        // password: selectedData.password,
        id: selectedData.id,
      },
      function () {
        this.manageImageFiles();
      }
    );
  };

  handleBrandLogo = (e) => {
    if (e.target.files.length > 0) {
      this.setState(
        {
          brandLogo: e.target.value,
          brandLogoFile: e.target.files[0],
        },
        function () {
          let image = URL.createObjectURL(this.state.brandLogoFile);
          this.setState({ brandLogoPreview: image });
        }
      );
    }
  };

  handleCancel = () => {
    this.setState({
      address: "",
      contact: "",
      email: "",
      error: {},
      imagePreview: "",
      firstName: "",
      lastName: "",
      password: "",
      photo: "",
      photoFile: "",
      username: "",
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  handFavIcon = (e) => {
    if (e.target.files.length > 0) {
      this.setState(
        {
          favIcon: e.target.value,
          favIconFile: e.target.files[0],
        },
        function () {
          let image = URL.createObjectURL(this.state.favIconFile);
          this.setState({ favIconPreview: image });
        }
      );
    }
  };

  handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      this.setState(
        {
          photo: e.target.value,
          photoFile: e.target.files[0],
        },
        function () {
          let image = URL.createObjectURL(this.state.photoFile);
          this.setState({ imagePreview: image });
        }
      );
    }
  };

  handleRemoveBrandLogo = () => {
    this.setState({ brandLogo: "", brandLogoFile: "", brandLogoPreview: "" });
  };

  handleRemoveFavIcon = () => {
    this.setState({ favIcon: "", favIconFile: "", favIconPreview: "" });
  };

  handleSave = () => {
    let formData = new FormData();
    this.setState({ error: {} }, function () {
      let error = this.validateData();
      if (Object.keys(error).length !== 0) {
        this.setState({ error }, function () {
          swal("Error", "Fill all the necessary fields", "error");
        });
        return false;
      }
      let param = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        address: this.state.address,
        email: this.state.email,
        contact: this.state.contact,
        password: this.state.password,
        has_existing_system: this.state.hasExistingSystem,
        brand_name: this.state.brandDisplayName,
        odeeUrl: this.state.brandUrl,
        primaryColor: this.state.brandPrimaryColor,
        secondaryColor: this.state.brandSecondaryColor,
      };
      // let brand = {
      //   brand_name: this.state.brandDisplayName,
      //   odeeUrl: this.state.brandUrl,
      //   primaryColor: this.state.brandPrimaryColor,
      //   secondaryColor: this.state.brandSecondaryColor,
      // };
      // formData.append("brand", JSON.stringify(brand));
      if (this.state.brandLogoFile !== "") {
        formData.append("logo", this.state.brandLogoFile);
      } else {
        formData.append("logo", []);
      }
      formData.append("reseller", JSON.stringify(param));
      if (this.state.photoFile !== "") {
        formData.append("file", this.state.photoFile);
      }
      // else {
      //   formData.append("file", []);
      // }
      if (this.state.favIconFile !== "") {
        formData.append("favIcon", this.state.favIconFile);
      } else {
        formData.append("favIcon", []);
      }
      this.setState({ spinner: true }, function () {
        axiosPost(
          URLs.insertReseller,
          formData,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.insertSuccess, "success");
              this.props.getResellers();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    });
  };

  handleUpdate = () => {
    let formData = new FormData();
    let param = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      address: this.state.address,
      email: this.state.email,
      contact: this.state.contact,
      // username: this.state.username,
      id: this.state.id,
      brand_name: this.state.brandDisplayName,
      odeeUrl: this.state.brandUrl,
      primaryColor: this.state.brandPrimaryColor,
      secondaryColor: this.state.brandSecondaryColor,
      // password: this.state.password,
    };
    // let brand = {
    //   brand_id: this.props.selectedData.brand.id,
    //   odeeUrl: this.state.brandUrl,
    //   brand_name: this.state.brandDisplayName,
    //   primaryColor: this.state.brandPrimaryColor,
    //   secondaryColor: this.state.brandSecondaryColor,
    // };
    // formData.append("brand", JSON.stringify(brand));
    formData.append("reseller", JSON.stringify(param));
    if (this.state.photoFile !== "") {
      formData.append("file", this.state.photoFile);
    }
    if (this.state.brandLogoFile !== "") {
      formData.append("logo", this.state.brandLogoFile);
    }
    if (this.state.favIconFile !== "") {
      formData.append("favIcon", this.state.favIconFile);
    }
    // else {
    //   formData.append("file", []);
    // }
    this.setState({ spinner: true }, function () {
      axiosPost(
        URLs.updateReseller,
        formData,
        (response) => {
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
            this.props.getResellers();
            this.props.handleCreateModal();
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  manageImageFiles = () => {
    let selectedData = this.props.selectedData;
    if (selectedData.media && selectedData.media.length > 0) {
      this.setState({
        imagePreview:
          fileUrl +
          "/" +
          selectedData.media[0].id +
          "/" +
          selectedData.media[0].file_name,
      });
    }
    if (selectedData.media && selectedData.media.length > 0) {
      selectedData.media.forEach((el) => {
        if (el.collection_name === "logo") {
          this.setState({
            brandLogoPreview: fileUrl + "/" + el.id + "/" + el.file_name,
          });
        }
        if (el.collection_name === "icon") {
          this.setState({
            favIconPreview: fileUrl + "/" + el.id + "/" + el.file_name,
          });
        }
      });
    }
  };

  removeImage = () => {
    this.setState({ photo: "", imagePreview: "", photoFile: "" });
  };

  validateData = () => {
    let error = {};
    if (this.state.address === "") {
      error.address = true;
    }
    if (this.state.firstName === "") {
      error.name = true;
    }
    if (this.state.lastName === "") {
      error.name = true;
    }
    if (this.state.email === "") {
      error.email = true;
    }
    if (this.state.contact === "") {
      error.contact = true;
    }
    // if (this.state.username === "") {
    //   error.username = true;
    // }
    if (this.state.password === "") {
      error.password = true;
    }
    if (this.state.brandDisplayName === "") {
      error.brandDisplayName = true;
    }
    if (this.state.brandPrimaryColor === "") {
      error.brandPrimaryColor = true;
    }
    if (this.state.brandSecondaryColor === "") {
      error.brandSecondaryColor = true;
    }
    if (this.state.brandLogo === "") {
      error.brandLogo = true;
    }
    return error;
  };

  render() {
    return (
      <div className="container-fluid">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        {this.state.imagePreview && this.state.imagePreview !== "" ? (
          <div className="row mb-2">
            <div className="col text-center">
              <div
                className="imageHolder"
                style={{ width: "70px", height: "70px" }}
              >
                <img src={this.state.imagePreview} />
              </div>
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-md-1"></div>

          <div className="col-md-3 mt-1">
            <strong>Photo</strong>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col">
                {this.props.edit &&
                this.state.imagePreview &&
                this.state.photo === "" ? (
                  <input
                    className="form-control"
                    disabled
                    type="text"
                    value={this.props.selectedData.media[0].name}
                  />
                ) : (
                  <input
                    accept="image/png, image/jpeg"
                    className="form-control"
                    name="photo"
                    onChange={this.handleImageChange}
                    type="file"
                    value={this.state.photo}
                  />
                )}
              </div>
              {this.state.imagePreview && this.state.imagePreview !== "" ? (
                <div className="col-md-auto">
                  <CustomButton
                    className="cancelBtn"
                    onClick={this.removeImage}
                    text="Remove"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>First Name</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.firstName ? "invalid" : "")
              }
              name="firstName"
              onChange={this.handleChange}
              type="text"
              value={this.state.firstName}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Last Name</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.lastName ? "invalid" : "")
              }
              name="lastName"
              onChange={this.handleChange}
              type="text"
              value={this.state.lastName}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Address</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.address ? "invalid" : "")
              }
              name="address"
              onChange={this.handleChange}
              type="text"
              value={this.state.address}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Email</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.email ? "invalid" : "")
              }
              name="email"
              onChange={this.handleChange}
              type="email"
              value={this.state.email}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Contact No</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.contact ? "invalid" : "")
              }
              name="contact"
              onChange={this.handleChange}
              type="text"
              value={this.state.contact}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        {this.props.edit ? null : (
          <div className="row mt-2">
            <div className="col-md-1"></div>
            <div className="col-md-3 mt-1">
              <strong>Has Existing System</strong>
            </div>
            <div className="col-md-7 text-center">
              <input
                type="checkbox"
                className="form-check-input"
                name="hasExistingSystem"
                onChange={this.handleChange}
                checked={this.state.hasExistingSystem}
              />
            </div>
            <div className="col-md-1"></div>
          </div>
        )}

        {/* <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Username</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.username ? "invalid" : "")
              }
              name="username"
              onChange={this.handleChange}
              type="text"
              value={this.state.username}
            />
          </div>
          <div className="col-md-1"></div>
        </div> */}
        {this.props.edit ? null : (
          <>
            <hr></hr>
            <div className="row mt-2">
              <div className="col-md-1"></div>
              <div className="col-md-3 mt-1">
                <strong>Password</strong>
              </div>
              <div className="col-md-7">
                <input
                  className={
                    "form-control " +
                    (this.state.error.password ? "invalid" : "")
                  }
                  name="password"
                  onChange={this.handleChange}
                  type="password"
                  value={this.state.password}
                />
              </div>
              <div className="col-md-1"></div>
            </div>
          </>
        )}

        <div className="resellerBrandBox">
          <div className="resellerBrandHeader">
            Brand Information [ For UI Purpose ]
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 mb-2">
                <div className="row">
                  <div className="col-md-5 mt-2">
                    <strong>Display Name</strong>
                  </div>
                  <div className="col-md-7">
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (this.state.error.brandDisplayName ? "invalid" : "")
                      }
                      name="brandDisplayName"
                      value={this.state.brandDisplayName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-5 mt-2">
                    <strong>URL</strong>
                  </div>
                  <div className="col-md-7">
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (this.state.error.brandUrl ? "invalid" : "")
                      }
                      name="brandUrl"
                      value={this.state.brandUrl}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-5 mt-2">
                    <strong>Primary Color</strong>
                  </div>
                  <div className="col-md-7 text-center">
                    <input
                      type="color"
                      className={
                        "form-control form-control-color " +
                        (this.state.error.brandPrimaryColor ? "invalid" : "")
                      }
                      // className="form-control form-control-color"
                      name="brandPrimaryColor"
                      value={this.state.brandPrimaryColor}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-5 mt-2">
                    <strong>Secondary Color</strong>
                  </div>
                  <div className="col-md-7">
                    <input
                      type="color"
                      className={
                        "form-control form-control-color " +
                        (this.state.error.brandSecondaryColor ? "invalid" : "")
                      }
                      name="brandSecondaryColor"
                      value={this.state.brandSecondaryColor}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-5 text-center">
                <div className="resellerBrandLogoBox">
                  {this.state.brandLogoPreview &&
                  this.state.brandLogoPreview !== "" ? (
                    <>
                      <div
                        className="imageHolder"
                        style={{ width: "100%", height: "100%" }}
                      >
                        <img
                          src={this.state.brandLogoPreview}
                          className="resellerBrandLogo"
                        />
                      </div>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="resellerBrandRemoveIcon"
                        size="lg"
                        onClick={this.handleRemoveBrandLogo}
                      />
                    </>
                  ) : (
                    <>
                      <input
                        id="image"
                        type="file"
                        accept="image/png, image/jpeg"
                        ref={(ref) => (this.upload = ref)}
                        style={{ display: "none" }}
                        onChange={this.handleBrandLogo}
                        value={this.state.brandLogo}
                        multiple
                      />
                      <div
                        className={
                          "resellerAddLogo " +
                          (this.state.error.brandLogo ? "invalid" : "")
                        }
                        style={{ border: "1px solid" }}
                        onClick={() => {
                          this.upload.click();
                        }}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                        Add Logo
                      </div>
                    </>
                  )}
                </div>
                <div className="resellerBrandLogoBox mt-3 mb-2">
                  {this.state.favIconPreview &&
                  this.state.favIconPreview !== "" ? (
                    <>
                      <div
                        className="imageHolder"
                        style={{ width: "100%", height: "100%" }}
                      >
                        <img
                          src={this.state.favIconPreview}
                          className="resellerBrandLogo"
                        />
                      </div>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="resellerBrandRemoveIcon"
                        size="lg"
                        onClick={this.handleRemoveFavIcon}
                      />
                    </>
                  ) : (
                    <>
                      <input
                        id="image"
                        type="file"
                        accept="image/png, image/jpeg"
                        ref={(ref1) => (this.upload1 = ref1)}
                        style={{ display: "none" }}
                        onChange={this.handFavIcon}
                        value={this.state.favIcon}
                        multiple
                      />
                      <div
                        className={
                          "resellerAddLogo " +
                          (this.state.error.favIcon ? "invalid" : "")
                        }
                        style={{ border: "1px solid" }}
                        onClick={() => {
                          this.upload1.click();
                        }}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                        Add Favicon
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col text-end">
            {this.props.edit ? (
              <CustomButton
                className="me-2"
                onClick={this.handleUpdate}
                text="Update"
              />
            ) : (
              <CustomButton
                className="me-2"
                onClick={this.handleSave}
                text="Add"
              />
            )}

            <CustomButton
              className="cancelBtn"
              onClick={this.handleCancel}
              text="Cancel"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CreateReseller;
