/**
 * My Details Component - Detail information of all Advisors, Users and Reseller can be found here.
 *
 *
 * @author Sandeep Shakya
 */

import React, { Component } from "react";
import { Spinner } from "reactstrap";
import CustomButton from "../../common/CustomButton";
import BillingInfo from "./BillingInfo";
import PersonalInfo from "./PersonalInfo";
import CustomModal from "../../common/CustomModal";
import EditInformationModal from "./EditInformationModal";
import { checkRolePermissions, checkUserRoles } from "../../utils/Helper";
import { fileUrl, moduleName, URL, userRole } from "../../utils/Constants";
import { axiosGet } from "../../utils/AxiosApi";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import CreateBillingInfo from "../Advisors/CreateBillingInfo";

class MyDetails extends Component {
  state = {
    address: "",
    billingInformation: {},
    billingModal: false,
    contact: "",
    editModal: false,
    email: "",
    hasBillingDetails: false,
    id: "",
    imageMedia: {},
    isReseller: false,
    isAdmin: false,
    loader: false,
    name: "",
    photo: "",
    username: "",
  };

  componentDidMount = () => {
    this.permissions();
  };

  getDetails = () => {
    this.setState({ loader: true }, function () {
      let url = this.getUrl();
      axiosGet(
        url,
        (response) => {
          if (response.status === 200) {
            let datum = response.data.data.item;
            // if (datum.has_billing_details) {
            //   axiosGet(URL.getBillingInformation + datum.id, (res) => {
            //     if (res.status === 200) {
            //       let billData = res.data.data.item;
            //       this.setState({
            //         billingInformation: {
            //           name: billData.name,
            //           address: billData.address,
            //           email: billData.email,
            //           contact: billData.contact,
            //         },
            //       });
            //     }
            //   });
            // }
            this.setState({
              id: datum.id,
              address: datum.user.address,
              email: datum.user.email,
              name: datum.user.first_name + " " + datum.user.last_name,
              contact: datum.user.contact,
              // hasBillingDetails: datum.has_billing_details,
              photo:
                datum.media && datum.media.length > 0
                  ? fileUrl +
                    "/" +
                    datum.media[0].id +
                    "/" +
                    datum.media[0].file_name
                  : "",
              imageMedia:
                datum.media && datum.media.length > 0 ? datum.media[0] : {},
              // username: datum.user.username,
              loader: false,
            });
          }
        },
        (err) => {
          this.setState({ loader: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  getUrl = () => {
    if (checkRolePermissions("myDetails", moduleName.rootUser)) {
      return URL.getRootUserInfo;
    } else if (checkRolePermissions("myDetails", moduleName.admin)) {
      return URL.getAdminInfo;
    } else if (checkRolePermissions("myDetails", moduleName.reseller)) {
      return URL.getResellerInfo;
    } else if (checkRolePermissions("myDetails", moduleName.advisor)) {
      return URL.getAdvisorInfo;
    } else {
      return URL.getClientInfo;
    }
  };

  handleBillingModal = () => {
    this.setState({
      billingModal: !this.state.billingModal,
    });
  };

  handleEditModal = () => {
    this.setState({ editModal: !this.state.editModal });
  };

  permissions = () => {
    this.setState(
      {
        isReseller: checkUserRoles(userRole.reseller),
        isAdmin: checkUserRoles(userRole.admin),
      },
      function () {
        this.getDetails();
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col">
              <div className="titleHeaderStyle">My Details</div>
            </div>
          </div>
        </div>
        <div className="container mt-2">
          {/* <div className="row">
            <div className="col text-end">
              <CustomButton
                onClick={this.handleEditModal}
                text="Edit Info"
              ></CustomButton>
            </div>
          </div> */}
          <div className="row">
            {this.state.loader ? (
              <div className="col text-center p-4">
                <Spinner></Spinner>
                <br></br>Loading ...
              </div>
            ) : (
              <>
                <div className="col-md-8">
                  <PersonalInfo
                    address={this.state.address}
                    contact={this.state.contact}
                    email={this.state.email}
                    isAdmin={this.state.isAdmin}
                    name={this.state.name}
                    // username={this.state.username}
                  />
                  {/* {this.state.hasBillingDetails ? (
                    <BillingInfo
                      billingInformation={this.state.billingInformation}
                    />
                  ) : !this.state.isReseller && !this.state.isAdmin ? (
                    <div className="text-center p-4">
                      <CustomButton
                        onClick={this.handleBillingModal}
                        text="Add Billing Information"
                      />
                    </div>
                  ) : null} */}
                </div>
                <div
                  className="col-md-4 text-center"
                  style={{ borderLeft: "1px solid #ccc" }}
                >
                  <div className="mt-4">
                    <div>
                      <img src={this.state.photo} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <CustomModal
          modal={this.state.editModal}
          modalBody={
            <EditInformationModal
              address={this.state.address}
              billingInformation={this.state.billingInformation}
              contact={this.state.contact}
              edit={true}
              email={this.state.email}
              hasBillingDetails={this.state.hasBillingDetails}
              imageMedia={this.state.imageMedia}
              isAdmin={this.state.isAdmin}
              name={this.state.name}
              photo={this.state.photo}
            />
          }
          modalHeader="Edit Information"
          size={this.state.isAdmin ? "md" : "xl"}
          toggleModal={this.handleEditModal}
        />
        <CustomModal
          modal={this.state.billingModal}
          modalBody={
            <CreateBillingInfo
              getDetails={this.getDetails}
              handleBillingModal={this.handleBillingModal}
              myDetails={true}
              selectedId={this.state.id}
            />
          }
          modalHeader={"Create Billing Information"}
          toggleModal={this.handleBillingModal}
        />
      </React.Fragment>
    );
  }
}

export default MyDetails;
