import React from "react";

function UpcomingInvoiceDetails(props) {
  return (
    <div className="container-fluid">
      {props.datas.length > 0
        ? props.datas.map((data, idx) => {
            return (
              <div className="row invoiceDetailBox" key={idx}>
                <div className="col-md-1">{idx + 1}. </div>
                <div className="col-md-8">
                  {data.type === "product"
                    ? data.product.name
                    : `Subscribed for - ${data.subscription.products.name}`}
                </div>
                <div
                  className="col-md-3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <strong>AUD</strong>&nbsp;${data.price}
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
}

export default UpcomingInvoiceDetails;
