import React, { Component } from "react";
import swal from "sweetalert";
import CustomButton from "../../common/CustomButton";
import { axiosGet } from "../../utils/AxiosApi";
import { URL as URLs } from "../../utils/Constants";
import ProductImageCreateList from "./ProductImageCreateList";

class CreateProductsServices extends Component {
  state = {
    currency: "AUD",
    currencies: [],
    description: "",
    end_user_tier_product: "",
    error: {},
    interval: "",
    intervalList: [
      { name: "Daily", id: 1 },
      { name: "Weekly", id: 2 },
      { name: "Monthly", id: 3 },
      { name: "Yearly", id: 4 },
    ],
    name: "",
    photoFiles: [],
    photos: [],
    preview: "",
    status: "",
    type: "",
    typeList: [
      { id: 1, name: "one-off" },
      { id: 2, name: "recurring" },
    ],
    unitAmount: "",
  };

  componentDidMount() {
    this.getCurrencies();
  }

  getCurrencies = () => {
    axiosGet(URLs.getCurrencies, (res) => {
      if (res.status === 200) {
        this.setState({ currencies: res.data.data.items });
      }
    });
  };

  handleCancel = () => {
    this.setState({
      currency: "",
      description: "",
      error: {},
      interval: "",
      name: "",
      photos: [],
      type: "",
      unitAmount: "",
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleImageChange = (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let photos = [...this.state.photos],
        photoFiles = [...this.state.photoFiles];
      for (let i = 0; i < files.length; i++) {
        photos.push(URL.createObjectURL(files[i]));
        photoFiles.push(files[i]);
      }
      this.setState({ photoFiles, photos }, function () {
        this.setState({
          preview: this.state.photos[this.state.photos.length - 1],
        });
      });
    }
  };

  previewImage = (idx) => {
    let photos = [...this.state.photos];
    this.setState({ preview: photos[idx] });
  };

  removeImage = (e, idx) => {
    e.stopPropagation();
    let photos = [...this.state.photos];
    let photoFiles = [...this.state.photoFiles];
    if (this.state.preview === photos[idx]) {
      this.setState({ preview: "" });
    }
    photos.splice(idx, 1);
    photoFiles.splice(idx, 1);
    this.setState({ photos, photoFiles });
  };

  save = () => {
    this.setState({ error: {} }, function () {
      let error = this.validateData();
      if (Object.keys(error).length !== 0) {
        this.setState({ error }, function () {
          swal("Error", "Fill all the necessary fields", "error");
        });
        return false;
      }
      let param = {
        currency: this.state.currency,
        description: this.state.description,
        interval: this.state.interval,
        name: this.state.name,
        photoFiles: this.state.photoFiles,
        photos: this.state.photos,
        type: this.state.type,
        unitAmount: this.state.unitAmount,
        status: this.state.status,
        end_user_tier_product: this.state.end_user_tier_product,
      };
      this.props.handleSave(param);
    });
  };

  validateData = () => {
    let error = {};
    if (this.state.name === "") {
      error.name = true;
    }
    if (this.state.unitAmount === "") {
      error.unitAmount = true;
    }
    return error;
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-3 mt-1">
                <strong>Name</strong>
              </div>
              <div className="col">
                <input
                  className={
                    "form-control " + (this.state.error.name ? "invalid" : "")
                  }
                  name="name"
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.name}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3 mt-1">
                <strong>Description</strong>
              </div>
              <div className="col">
                <textarea
                  name="description"
                  value={this.state.description}
                  className="form-control"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3 mt-1">
                <strong>Type</strong>
              </div>
              <div className="col">
                <select
                  name="type"
                  className="form-select"
                  value={this.state.type}
                  onChange={this.handleChange}
                >
                  <option value="" disabled selected>
                    Choose Product Type
                  </option>
                  {this.state.typeList.length > 0
                    ? this.state.typeList.map((type, idx) => {
                        return (
                          <option value={type.id} key={idx}>
                            {type.name}
                          </option>
                        );
                      })
                    : null}
                </select>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3 mt-1">
                <strong>Status</strong>
              </div>
              <div className="col">
                <select
                  name="status"
                  className="form-select"
                  value={this.state.status}
                  onChange={this.handleChange}
                >
                  <option value="" disabled selected>
                    Choose Status
                  </option>
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-3 mt-1">
                <strong>End User Tier Product</strong>
              </div>
              <div className="col">
                <select
                  name="end_user_tier_product"
                  className="form-select"
                  value={this.state.end_user_tier_product}
                  onChange={this.handleChange}
                >
                  <option value="" disabled selected>
                    Is it end user tier product?
                  </option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div className="priceBox">
              <strong>PRICE</strong>
              <div className="row mt-3">
                <div className="col-md-3 pe-0">
                  Currency
                  <select
                    name="currency"
                    className="form-select"
                    value={this.state.currency}
                    onChange={this.handleChange}
                    disabled={true}
                  >
                    <option value="" disabled selected>
                      Select Currency
                    </option>
                    {this.state.currencies.map((currency, idx) => {
                      return (
                        <option value={currency.currency} key={idx}>
                          {currency.currency}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col">
                  Amount
                  <input
                    type="text"
                    className={
                      "form-control " +
                      (this.state.error.unitAmount ? "invalid" : "")
                    }
                    name="unitAmount"
                    value={this.state.unitAmount}
                    onChange={this.handleChange}
                  ></input>
                </div>
              </div>
              {/* {this.state.type === "2" ? (
                <div className="row mt-2">
                  <div className="col-md-3 mt-2 text-end">Interval</div>
                  <div className="col">
                    <select
                      name="interval"
                      value={this.state.interval}
                      className="form-select"
                      onChange={this.handleChange}
                    >
                      <option value="" disabled selected>
                        Choose Payment Interval
                      </option>
                      {this.state.intervalList.length > 0
                        ? this.state.intervalList.map((item, idx) => {
                            return (
                              <option value={item.id} key={idx}>
                                {item.name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>
              ) : null} */}
            </div>
          </div>
          <div className="col-md-5" style={{ borderLeft: "1px solid #ccc" }}>
            <ProductImageCreateList
              handleImageChange={this.handleImageChange}
              photos={this.state.photos}
              preview={this.state.preview}
              previewImage={this.previewImage}
              removeImage={this.removeImage}
            />
          </div>
        </div>
        <hr></hr>
        <div className="row mt-3">
          <div className="col text-end">
            <CustomButton text="Save" onClick={this.save} />
            <CustomButton
              text="Cancel"
              className="ms-2 cancelBtn"
              onClick={this.handleCancel}
            ></CustomButton>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateProductsServices;
