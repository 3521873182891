import React, { Component } from "react";
import swal from "sweetalert";
import CustomButton from "../../common/CustomButton";
import CustomFullSpinner from "../../common/CustomFullSpinner";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert, DISPLAYMESSAGE } from "../../utils/DisplayMessage";

class CreateBillingInfo extends Component {
  state = {
    address: "",
    contact: "",
    email: "",
    id: "",
    name: "",
    spinner: false,
  };

  assignId = () => {
    this.setState({ id: this.props.selectedId }, function () {
      if (this.props.view) {
        axiosGet(
          URL.getBillingInformation + this.state.id,
          (response) => {
            if (response.status === 200) {
              let datum = response.data.data.item;
              this.setState({
                address: datum.address,
                contact: datum.contact,
                email: datum.email,
                name: datum.name,
              });
            }
          },
          (err) => {
            displayErrorAlert(err);
          }
        );
      }
    });
  };

  componentDidMount() {
    this.assignId();
  }

  handleCancel = () => {
    this.setState({
      address: "",
      contact: "",
      email: "",
      name: "",
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSave = () => {
    let param = {
      name: this.state.name,
      email: this.state.email,
      contact: this.state.contact,
      address: this.state.address,
      advisor_id: this.state.id,
    };
    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.createBillingInformation,
        param,
        (response) => {
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.insertSuccess, "success");
            if (this.props.myDetails) {
              this.props.handleBillingModal();
              this.props.getDetails();
            } else {
              this.props.handleBillingModal("", "");
              this.props.getAdvisors();
            }
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  render() {
    return (
      <div className="container-fluid">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Name</strong>
          </div>
          <div className="col-md-7">
            <input
              className="form-control"
              disabled={this.props.view}
              name="name"
              onChange={this.handleChange}
              type="text"
              value={this.state.name}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Address</strong>
          </div>
          <div className="col-md-7">
            <input
              className="form-control"
              disabled={this.props.view}
              name="address"
              onChange={this.handleChange}
              type="text"
              value={this.state.address}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Email</strong>
          </div>
          <div className="col-md-7">
            <input
              className="form-control"
              disabled={this.props.view}
              name="email"
              onChange={this.handleChange}
              type="text"
              value={this.state.email}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Contact</strong>
          </div>
          <div className="col-md-7">
            <input
              className="form-control"
              disabled={this.props.view}
              name="contact"
              onChange={this.handleChange}
              type="text"
              value={this.state.contact}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        {this.props.view ? null : (
          <div className="row mt-3">
            <div className="col text-end">
              <CustomButton
                className="me-2"
                onClick={this.handleSave}
                text="Save"
              />
              <CustomButton
                className="cancelBtn"
                onClick={this.handleCancel}
                text="Cancel"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CreateBillingInfo;
