import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { cardType, URL, userRole } from "../utils/Constants";
import { checkUserRoles } from "../utils/Helper";
import moment from "moment";
import IsFirstLogin from "./IsFirstLogin";
import { axiosGet } from "../utils/AxiosApi";
import CustomModal from "../common/CustomModal";
import PayNow from "./PayNow";
import { displayErrorAlert } from "../utils/DisplayMessage";
import AdvisorRegistrationPaymentDisplay from "./AdvisorRegistrationPaymentDisplay";
class Dashboard extends Component {
  state = {
    accountName: "Techart Trekkies (1230-1232-1232-3131)",
    isAdvisor: false,
    isClient: false,
    // isFirstLogin: localStorage.getItem("isFirstLogin"),
    loader: false,
    outstandingBalance: 1000,
    payNowModal: false,
    transactions: [],
    trainingFees: 0,
    subscriptionFees: 0,
    advanceSubscriptionFees: 0,
    totalFees: 0,
    currency: "",
    requirePaymentMethod: JSON.parse(
      localStorage.getItem("requirePaymentMethod")
    ),
  };

  componentDidMount() {
    this.permissions();
  }

  getAdvisorBill = () => {
    axiosGet(URL.getAdvisorRegistrationBill, (response) => {
      if (response.status == 200) {
        let datum = response.data.data,
          trainingFees = datum.training_fee,
          subscriptionFees = datum.initial_subscription_fees,
          advanceSubscriptionFees = datum.monthy_subscription_fees,
          totalFees = 0,
          currency = datum.currency;

        totalFees = trainingFees + subscriptionFees + advanceSubscriptionFees;
        this.setState({
          trainingFees,
          subscriptionFees,
          totalFees,
          advanceSubscriptionFees,
          currency,
        });
      }
    });
  };

  getAdvisorLatestTransaction = () => {
    this.setState({ loader: true, transactions: [] }, function () {
      axiosGet(
        URL.getPaymentIntent,
        (response) => {
          if (response.status === 200) {
            this.setState({
              loader: false,
              transactions: response.data.data.items,
            });
          }
        },
        (err) => {
          this.setState({ loader: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handlePayNow = () => {
    this.setState({ payNowModal: !this.state.payNowModal });
  };

  permissions = () => {
    this.setState(
      {
        isAdvisor: checkUserRoles(userRole.advisor),
        isClient: checkUserRoles(userRole.client),
      },
      function () {
        if (this.state.isAdvisor || this.state.isClient) {
          this.getAdvisorLatestTransaction();
        }
      }
    );
  };

  render() {
    return (
      <>
        {this.props.isFirstLogin && this.state.requirePaymentMethod ? (
          <IsFirstLogin
            isClient={this.state.isClient}
            isAdvisor={this.state.isAdvisor}
            getAdvisorLatestTransaction={this.getAdvisorLatestTransaction}
          ></IsFirstLogin>
        ) : this.state.isAdvisor || this.state.isClient ? (
          <div
            className="container-fluid"
            style={{
              backgroundColor: "#f4f5f7",
              height: "100%",
            }}
          >
            <div className="row pt-3">
              <div className="col text-center">
                <h3 className="mb-0">WELCOME TO </h3>
                <div style={{ fontSize: "35px" }} className="companyHeader">
                  <span>ODEE</span> Payments System
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <i>
                  You are logged in as{" "}
                  <strong>{this.state.isAdvisor ? "Advisor" : "Client"}</strong>
                </i>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <AdvisorRegistrationPaymentDisplay
                  advanceSubscriptionFees={this.state.advanceSubscriptionFees}
                  handlePayNow={this.handlePayNow}
                  isSubscribed={this.props.isSubscribed}
                  subscriptionFees={this.state.subscriptionFees}
                  totalFees={this.state.totalFees}
                  trainingFees={this.state.trainingFees}
                  currency={this.state.currency}
                />
                <div className="outstandingBalanceBox mt-3">
                  <div className="row mb-3">
                    <div className="col">
                      <strong>
                        <i>Latest 5 Transactions</i>
                      </strong>
                    </div>
                    <div className="col text-end linkText">
                      <Link to="/dashboard/billing-history">View More</Link>
                    </div>
                  </div>
                  <table className="table table-striped mb-0 table-bordered">
                    <thead>
                      <tr>
                        <th>Transaction ID</th>
                        <th>Description</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Payment Method</th>
                        <th>Payment Status</th>
                        <th>Invoice ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.transactions.length > 0 ? (
                        this.state.transactions.map((item, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{item.stripe_payment_intent_id}</td>
                              <td>{item.invoice_details.title}</td>
                              <td>
                                {item.invoice_details.payment_date
                                  ? moment(
                                      item.invoice_details.payment_date
                                    ).format("ll")
                                  : null}
                              </td>
                              <td>AUD ${item.invoice_details.price}</td>
                              <td>
                                {item.stripe.status === "succeeded" ? (
                                  <span>
                                    {item.stripe.charges.data[0]
                                      .payment_method_details.card.brand ===
                                    cardType.visa ? (
                                      <FontAwesomeIcon
                                        icon={faCcVisa}
                                        color="blue"
                                        size="lg"
                                      ></FontAwesomeIcon>
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={faCcMastercard}
                                        color="darkred"
                                        size="lg"
                                      ></FontAwesomeIcon>
                                    )}{" "}
                                    <span style={{ fontSize: "13px" }}>
                                      <strong>
                                        {item.stripe.charges.data[0].payment_method_details.card.brand.toUpperCase()}{" "}
                                        -{" "}
                                        {
                                          item.stripe.charges.data[0]
                                            .payment_method_details.card.last4
                                        }
                                      </strong>
                                    </span>
                                  </span>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td className="text-center">
                                <span
                                  className={
                                    item.stripe.status === "succeeded"
                                      ? "paidChip"
                                      : "unpaidChip"
                                  }
                                >
                                  {item.stripe.status === "succeeded"
                                    ? "Paid"
                                    : "Pending"}
                                </span>
                              </td>
                              <td>
                                {item.invoice_details.invoice_number
                                  ? item.invoice_details.invoice_number
                                  : "-"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={7} className="text-center">
                            {this.state.loader ? (
                              <>
                                <Spinner></Spinner>
                                <br></br>
                                Loading Data...
                              </>
                            ) : (
                              "No Transaction Data"
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              alignItems: "center",
              backgroundColor: "#f4f5f7",
              display: "flex",
              flexDirection: "column",
              height: "100vh",
              justifyContent: "center",
            }}
          >
            <h1>WELCOME TO </h1>
            <div style={{ fontSize: "45px" }} className="companyHeader">
              <span>ODEE</span> Payments System
            </div>
          </div>
        )}
        <CustomModal
          modal={this.state.payNowModal}
          modalBody={
            <PayNow
              getAdvisorBill={this.getAdvisorBill}
              getAdvisorLatestTransaction={this.getAdvisorLatestTransaction}
              handlePayNow={this.handlePayNow}
              paymentData={this.state.totalFees}
              currency={this.state.currency}
            />
          }
          modalHeader="Pay Now"
          toggleModal={this.handlePayNow}
        ></CustomModal>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isFirstLogin: state.login.isFirstLogin,
  isSubscribed: state.login.isSubscribed,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(Dashboard);
