import { faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomButton from "../../common/CustomButton";
import CustomFullSpinner from "../../common/CustomFullSpinner";
import CustomModal from "../../common/CustomModal";
import { axiosDelete, axiosGet } from "../../utils/AxiosApi";
import { cardType, URL } from "../../utils/Constants";
import { displayErrorAlert, DISPLAYMESSAGE } from "../../utils/DisplayMessage";
import AddPaymentMethod from "./AddPaymentMethod";

/**
 * Component where your payment data is added
 *
 * @author Sandeep Shakya
 */

class BillingMethod extends Component {
  state = {
    billingMethods: [],
    createModal: false,
    edit: false,
    loader: false,
    spinner: false,
  };

  componentDidMount() {
    this.getBillingMethods();
  }

  getBillingMethods = () => {
    this.setState({ loader: true, billingMethods: [] }, function () {
      axiosGet(
        URL.getBillingMethod,
        (response) => {
          if (response.status === 200) {
            this.setState({
              billingMethods: response.data.data.items,
              loader: false,
            });
          }
        },
        (err) => {
          this.setState({ loader: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({ createModal: !this.state.createModal, edit: false });
  };

  handleDeleteCard = (id) => {
    axiosDelete(
      URL.deleteBillingMethod + id,
      (response) => {
        if (response.status === 200) {
          swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
          this.getBillingMethods();
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  setDefault = (item) => {
    this.setState({ spinner: true }, function () {
      axiosGet(
        URL.setDefaultBillingMethod + item.id,
        (response) => {
          if (response.status === 200) {
            swal("Success", "Default card successfully changed", "success");
            this.setState({ spinner: false });
            this.getBillingMethods();
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  render() {
    return (
      <>
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col">
              <div className="titleHeaderStyle">Billing Methods</div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-2">
            <div className="col">
              {this.state.billingMethods.length > 0 ? (
                <React.Fragment>
                  <div className="row">
                    <div className="col text-end">
                      <CustomButton
                        text="Add Payment Method"
                        onClick={this.handleCreateModal}
                      />
                    </div>
                  </div>
                  <div className="container">
                    <div className="row cardBox">
                      {this.state.billingMethods.map((item, idx) => {
                        return (
                          <div
                            className="col-md-6"
                            key={idx}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className={
                                "CreditCardHolder " +
                                (item.card.brand === cardType.visa
                                  ? "visa"
                                  : "mastercard")
                              }
                            >
                              <div className="row">
                                <div className="col">
                                  {item.card.brand === cardType.visa ? (
                                    <FontAwesomeIcon
                                      icon={faCcVisa}
                                      style={{
                                        fontSize: "35px",
                                      }}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faCcMastercard}
                                      style={{
                                        fontSize: "35px",
                                      }}
                                    />
                                  )}
                                </div>
                                <div
                                  className="col-md-6 text-end"
                                  style={{ fontSize: "13px" }}
                                >
                                  {item.is_default ? (
                                    <span
                                      style={{
                                        backgroundColor: "white",
                                        padding: "1px 10px",
                                        borderRadius: "3px",
                                      }}
                                    >
                                      Default
                                    </span>
                                  ) : (
                                    <span
                                      className="col creditCardBtn"
                                      onClick={() => this.setDefault(item)}
                                    >
                                      Set As Default
                                    </span>
                                  )}{" "}
                                  |{" "}
                                  <span
                                    className="col creditCardBtn"
                                    onClick={() =>
                                      this.handleDeleteCard(item.id)
                                    }
                                  >
                                    Delete
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <div className="cardNumber">
                                    xxxx - xxxx - xxxx - {item.card.last4}
                                  </div>
                                  <div className="cardText">
                                    {item.billing_details.name}
                                  </div>
                                  <div className="cardText">
                                    {`${
                                      item.billing_details.address.line1
                                        ? item.billing_details.address.line1 +
                                          ", "
                                        : ""
                                    }${
                                      item.billing_details.address.line2
                                        ? item.billing_details.address.line2 +
                                          ", "
                                        : ""
                                    }${item.billing_details.address.city}, ${
                                      item.billing_details.address.country
                                    }`}
                                  </div>
                                  <div
                                    className="cardText text-center"
                                    style={{ fontSize: "10px" }}
                                  >
                                    Valid Until:{" "}
                                    <strong>
                                      {item.card.exp_month} /{" "}
                                      {item.card.exp_year}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </React.Fragment>
              ) : this.state.loader ? (
                <div className="col text-center p-4">
                  <Spinner></Spinner>
                  <br></br>Loading Data...
                </div>
              ) : (
                <div className="row">
                  <div className="col text-center">
                    <div className="paymentDivHolder">
                      <div className="paymentDivHolderHeader">
                        No Payment Method Assigned Yet
                      </div>
                      <div className="mt-1">
                        <CustomButton
                          text="Add Payment Method"
                          onClick={this.handleCreateModal}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.createModal}
          modalBody={
            <AddPaymentMethod
              handleCreateModal={this.handleCreateModal}
              getBillingMethods={this.getBillingMethods}
              fromSystem={true}
            />
          }
          modalHeader={
            this.state.edit ? "Edit Payment Method" : "Add Payment Method"
          }
          toggleModal={this.handleCreateModal}
        />
      </>
    );
  }
}

export default BillingMethod;
