/**
 * Ducks pattern is being followerd for using Redux in this application.In ducks pattern
 * all the actions and dispatch functions of a components are described in a single file
 * and are combined to rootreducer using combineReducer function of redux.
 * @author Sandeep Shakya
 *
 */

import thunk from "redux-thunk";
import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import loginReducer from "./ducks/LoginDucks";

const rootReducer = combineReducers({
  login: loginReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const allStoreEnhancers = composeEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer, allStoreEnhancers);

export default store;
