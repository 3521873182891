import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";

class ProductImageCreateList extends Component {
  render() {
    let props = this.props;
    return (
      <div style={{ height: "100%" }}>
        <input
          id="image"
          type="file"
          accept="image/png, image/jpeg"
          ref={(ref) => (this.upload = ref)}
          style={{ display: "none" }}
          onChange={(e) => props.handleImageChange(e)}
          multiple
        />
        {props.photos.length > 0 ? (
          <>
            <div className="productPreview" style={{ height: "250px" }}>
              <img src={props.preview}></img>
            </div>

            <div className="productThumbnailHolder">
              {props.photos.map((photo, idx) => {
                return (
                  <div
                    className="productThumbnail"
                    key={idx}
                    onClick={() => props.previewImage(idx)}
                  >
                    <img src={photo} />

                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="thumbnailDelete"
                      onClick={(e) => props.removeImage(e, idx)}
                    />
                  </div>
                );
              })}

              <div
                className="productThumbnail"
                onClick={() => {
                  this.upload.click();
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90%",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              cursor: "pointer",
              width: "100%",
              border: "1px solid #ccc",
              flexDirection: "column",
            }}
            onClick={() => {
              this.upload.click();
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            <div>Click to add Images</div>
          </div>
        )}
      </div>
    );
  }
}

export default ProductImageCreateList;
