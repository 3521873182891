/**
 * All the Routes Path for the applicaton will be defined here
 *
 * @author Sandeep Shakya
 */

import React, { Component } from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";
import LoginPage from "../components/Login/LoginPage";
import Main from "../components/Main";
import PrivateRoute from "./PrivateRoute";

class Routes extends Component {
  render() {
    return (
      //  Define Route and path here
      <Switch>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route
          path="/dashboard/*"
          element={
            <PrivateRoute>
              <Main />
            </PrivateRoute>
          }
        />
        <Route
          path="/login"
          element={
            <LoginPath>
              <LoginPage />
            </LoginPath>
          }
        />
      </Switch>
    );
  }
}

function LoginPath({ children }) {
  let token = localStorage.getItem("token");
  if (token) {
    return <Navigate to="/dashboard" replace />;
  } else {
    return children;
  }
}

export default Routes;
