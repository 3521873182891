import {
  faCreditCard,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";

import CustomButton from "../../common/CustomButton";
import CustomFullSpinner from "../../common/CustomFullSpinner";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { getAdvisorStatus } from "../../ducks/LoginDucks";
import { displayErrorAlert, DISPLAYMESSAGE } from "../../utils/DisplayMessage";

/**
 * Form to add card Details
 *
 * @author Sandeep Shakya
 */
class AddPaymentMethod extends Component {
  state = {
    addressLine1: "",
    addressLine2: "",
    cardNumber: "",
    cities: [],
    city: "",
    cityLoader: false,
    contact: "",
    countries: [],
    country: "",
    cvv: "",
    email: "",
    error: {},
    expirationDate: "",
    isDefault: true,
    loader: false,
    name: "",
  };

  componentDidMount() {
    this.getUserDetails();
    this.getCountries();
  }

  getCitites = () => {
    this.setState({ cityLoader: true, cities: [], city: "" }, function () {
      axiosGet(
        URL.getCities + this.state.country,
        (res) => {
          if (res.status === 200) {
            this.setState({
              cities: res.data.data.item.cities,
              cityLoader: false,
            });
          }
        },
        (err) => {
          this.setState({ cityLoader: false });
        }
      );
    });
  };

  getCountries = () => {
    axiosGet(URL.getCountries, (res) => {
      if (res.status === 200) {
        this.setState({ countries: res.data.data.items }, function () {
          if (this.state.countries.length > 0) {
            let countries = this.state.countries;
            countries.forEach((el) => {
              if (el.name === "Australia") {
                this.setState({ country: el.code }, function () {
                  this.getCitites();
                });
              }
            });
          }
        });
      }
    });
  };

  getUserDetails = () => {
    axiosGet(URL.getAuthUserDetails, (response) => {
      if (response.status === 200) {
        let userDetails = response.data.data.item;
        this.setState({
          name: userDetails.name,
          email: userDetails.email,
          contact: userDetails.contact,
        });
      }
    });
  };

  handleCancel = () => {
    this.setState({
      addressLine1: "",
      addressLine2: "",
      cardNumber: "",
      city: "",
      cityLoader: false,
      contact: "",
      country: "",
      cvv: "",
      email: "",
      expirationDate: "",
      isDefault: false,
      loader: false,
      name: "",
    });
  };

  handleChange = (e) => {
    let name = e.target.name,
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value }, () => {
      if (name === "country") {
        this.getCitites();
      }
    });
  };

  handleSave = () => {
    this.setState({ error: {} }, function () {
      let error = this.validateData();
      if (Object.keys(error).length !== 0) {
        this.setState({ error }, function () {
          swal("Error", "Fill all the necessary fields", "error");
        });
        return false;
      }
      if (this.state.cardNumber.length !== 16) {
        error.cardNumber = true;
        this.setState({ error });
        swal("Error", "Card Number should have 16 digits", "error");
        return false;
      }
      if (this.state.cvv.length !== 3 && this.state.cvv.length !== 4) {
        error.cvv = true;
        this.setState({ error });
        swal("Error", "Secret key should be 3 or 4 digits", "error");
        return false;
      }
      let expDate = this.state.expirationDate.split("-");
      let param = {
        card_number: this.state.cardNumber,
        expiration_month: expDate[1],
        expiration_year: expDate[0],
        cvv_number: this.state.cvv,
        card_holder_name: this.state.name,
        card_holder_email: this.state.email,
        card_holder_phone: this.state.contact,
        city: this.state.city,
        country: this.state.country,
        is_default: this.state.isDefault,
        line1: this.state.addressLine1,
        line2: this.state.addressLine2,
      };
      this.setState({ loader: true }, function () {
        axiosPost(
          this.props.fromSystem
            ? URL.storePaymentMethodFromSystem
            : this.props.isClient
            ? URL.storeClientPaymentMethod
            : URL.createBillingMethod,
          param,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.insertSuccess, "success");
              if (this.props.isFirstLogin) {
                //here the api will set isFirstLogin to False
                this.props.getAdvisorStatus();
                if (this.props.isClient || this.props.isAdvisor) {
                  this.props.getAdvisorLatestTransaction();
                }
              } else {
                this.props.getBillingMethods();
                this.props.handleCreateModal();
              }
            }
          },
          (err) => {
            this.setState({ loader: false });
            displayErrorAlert(err);
          }
        );
      });
    });
  };

  showInfo = () => {
    swal(
      "Your card's security code (CVV) is the 3 or 4 digit number located on the back of most cards."
    );
  };

  validateData = () => {
    let error = {};
    if (this.state.cardNumber === "") {
      error.cardNumber = true;
    }
    if (this.state.expirationDate === "") {
      error.expirationDate = true;
    }
    if (this.state.cvv === "") {
      error.cvv = true;
    }
    if (this.state.name === "") {
      error.name = true;
    }
    if (this.state.email === "") {
      error.email = true;
    }
    if (this.state.contact === "") {
      error.contact = true;
    }
    if (this.state.country === "") {
      error.country = true;
    }
    if (this.state.city === "") {
      error.city = true;
    }
    return error;
  };

  render() {
    return (
      <div className="container-fluid">
        {this.state.loader ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="row">
          <div className="col text-center">
            <div className="payInfoBox m-0" style={{ fontSize: "13px" }}>
              Accepts VISA / Mastercard only
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4 mt-1">
            <strong>Card Number</strong>
          </div>
          <div className="col-md-8" style={{ position: "relative" }}>
            <input
              className={
                "form-control " + (this.state.error.cardNumber ? "invalid" : "")
              }
              name="cardNumber"
              onChange={this.handleChange}
              type="text"
              value={this.state.cardNumber}
            />
            <FontAwesomeIcon icon={faCreditCard} className="creditCardIcon" />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4 mt-1">
            <strong>Expiration Date</strong>
          </div>
          <div className="col-md-8">
            <input
              className={
                "form-control " +
                (this.state.error.expirationDate ? "invalid" : "")
              }
              name="expirationDate"
              onChange={this.handleChange}
              placeholder="Select Month and Year"
              type="month"
              value={this.state.expirationDate}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4 mt-1 pe-0" style={{ fontSize: "14px" }}>
            <strong>Security Code (CVV)</strong>
          </div>
          <div className="col-md-8" style={{ position: "relative" }}>
            <input
              className={
                "form-control " + (this.state.error.cvv ? "invalid" : "")
              }
              name="cvv"
              onChange={this.handleChange}
              type="text"
              value={this.state.cvv}
            />
            <FontAwesomeIcon
              className="creditCardIcon"
              icon={faQuestionCircle}
              onClick={this.showInfo}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <div className="titleHeaderStyle m-0" style={{ fontSize: "13px" }}>
              Billing Information
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <input
              className={
                "form-control " + (this.state.error.name ? "invalid" : "")
              }
              name="name"
              onChange={this.handleChange}
              type="text"
              value={this.state.name}
              placeholder="Enter CardHolder Name"
            />
          </div>
          <div className="col">
            <input
              className={
                "form-control " + (this.state.error.contact ? "invalid" : "")
              }
              name="contact"
              onChange={this.handleChange}
              type="text"
              value={this.state.contact}
              placeholder="Enter Contact Number"
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <input
              className={
                "form-control " + (this.state.error.email ? "invalid" : "")
              }
              name="email"
              onChange={this.handleChange}
              type="email"
              value={this.state.email}
              placeholder="Enter CardHolder Email"
            />
          </div>
          <div className="col">
            <div className="form-check mt-2">
              <input
                name="isDefault"
                className="form-check-input"
                onChange={this.handleChange}
                type="checkbox"
                checked={this.state.isDefault}
                id="isDefault"
              />
              <label htmlFor="isDefault" className="form-check-label">
                <strong>Is Default</strong>
              </label>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <select
              className={
                "form-select " + (this.state.error.country ? "invalid" : "")
              }
              name="country"
              value={this.state.country}
              onChange={this.handleChange}
            >
              <option value="" disabled selected>
                Choose Country
              </option>
              {this.state.countries.map((country, idx) => {
                return (
                  <option key={idx} value={country.code}>
                    {country.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col">
            <select
              className={
                "form-select " + (this.state.error.city ? "invalid" : "")
              }
              name="city"
              value={this.state.city}
              onChange={this.handleChange}
              disabled={this.state.country === ""}
            >
              <option value="" disabled selected>
                Choose City
              </option>
              {this.state.cities.length > 0 ? (
                this.state.cities.map((city, idx) => {
                  return (
                    <option key={idx} value={city.name}>
                      {city.name}
                    </option>
                  );
                })
              ) : (
                <option disabled>
                  {this.state.cityLoader ? "Loading Data..." : "No Data"}
                </option>
              )}
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <input
              className="form-control"
              name="addressLine1"
              onChange={this.handleChange}
              type="text"
              value={this.state.addressLine1}
              placeholder="Address Line 1 (Optional)"
            />
          </div>
          <div className="col">
            <input
              className="form-control"
              name="addressLine2"
              onChange={this.handleChange}
              type="text"
              value={this.state.addressLine2}
              placeholder="Address Line 2 (Optional)"
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            <CustomButton
              className="me-2"
              onClick={this.handleSave}
              text="Add"
            ></CustomButton>
            <CustomButton
              className="cancelBtn"
              onClick={this.handleCancel}
              text="Cancel"
            ></CustomButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapActionsToProps = {
  getAdvisorStatus,
};

export default connect(mapStateToProps, mapActionsToProps)(AddPaymentMethod);
