import React, { Component } from "react";
import { Route, Routes as Switch } from "react-router-dom";
import Advisors from "../components/Advisors/Advisors";
import EndUserSubscriptionFee from "../components/EndUserSubscriptionFee/EndUserSubscriptionFee";
import BillingHistory from "../components/BillingHistory/BillingHistory";
import ResellerBillingHistory from "../components/BillingHistory/ResellerBillingHistory";
import BillingMethod from "../components/BillingMethod/BillingMethod";
import Clients from "../components/Clients/Clients";
import Commissions from "../components/Commissions/Commissions";
import Dashboard from "../components/Dashboard";
import MyDetails from "../components/MyDetails/MyDetails";
import Resellers from "../components/Resellers/Resellers";
import ProductsServices from "../components/ProductsServices/ProductsServices";
import Invoices from "../components/Invoices/Invoices";
import ApiKeys from "../components/ApiKeys/ApiKeys";
import RootUsers from "../components/RootUser/RootUsers";
import Admins from "../components/Admins/Admins";
import UserLevels from "../components/UserLevels/UserLevels";

class ContentContainer extends Component {
  render() {
    return (
      <div
        style={{
          flex: 1,
          height: "100vh",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        {/* <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div>Hello</div>
            </div>
          </div>
        </div> */}
        <Switch>
          <Route path="/" element={<Dashboard />} />
          <Route path="/advisors" element={<Advisors />} />
          <Route path="/billing-history" element={<BillingHistory />} />
          <Route path="/billing-method" element={<BillingMethod />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/commissions" element={<Commissions />} />
          <Route path="/my-details" element={<MyDetails />} />
          <Route path="/products-services" element={<ProductsServices />} />
          <Route path="/resellers" element={<Resellers />} />
          <Route path="/tiers" element={<EndUserSubscriptionFee />} />
          <Route path="/transactions" element={<ResellerBillingHistory />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/apiKeys" element={<ApiKeys />} />
          <Route path="/root-users" element={<RootUsers />} />
          <Route path="/admins" element={<Admins />} />
          <Route path="/user-levels" element={<UserLevels />} />
        </Switch>
      </div>
    );
  }
}

export default ContentContainer;
