import React, { Component } from "react";
import { Spinner } from "reactstrap";
import CustomModal from "../../common/CustomModal";
import CustomButton from "../../common/CustomButton";
import CreateReseller from "./CreateReseller";
import { fileUrl, moduleName, URL } from "../../utils/Constants";
import { axiosDelete, axiosGet } from "../../utils/AxiosApi";
import { checkRolePermissions } from "../../utils/Helper";
import swal from "sweetalert";
import { DISPLAYMESSAGE } from "../../utils/DisplayMessage";

class Resellers extends Component {
  state = {
    createModal: false,
    dataSpinner: false,
    deletePermission: false,
    edit: false,
    editPermission: false,

    resellers: [],
    selectedData: {},
    travelAgencyData: {},
  };

  componentDidMount() {
    this.getResellers();
    this.permissions();
  }

  getResellers = () => {
    this.setState({ dataSpinner: true }, function () {
      axiosGet(
        URL.getAllReseller,
        (response) => {
          if (response.status === 200) {
            this.setState({
              resellers: response.data.data.items,
              dataSpinner: false,
            });
          }
        },
        (err) => {
          this.setState({ dataSpinner: false });
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({ createModal: !this.state.createModal, edit: false });
  };

  handleDelete = (item) => {
    axiosDelete(URL.deleteReseller + item.id, (response) => {
      if (response.status === 200) {
        swal("Succes", DISPLAYMESSAGE.deleteSuccess, "success");
        this.getResellers();
      }
    });
  };

  handleEdit = (item) => {
    this.setState({ selectedData: item, edit: true, createModal: true });
  };

  permissions = () => {
    this.setState({
      editPermission: checkRolePermissions("update", moduleName.reseller),
      deletePermission: checkRolePermissions("delete", moduleName.reseller),
    });
  };

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col">
              <div className="titleHeaderStyle">Resellers</div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          {checkRolePermissions("create", moduleName.reseller) ? (
            <div className="row mt-2">
              <div className="col text-end">
                <CustomButton
                  onClick={this.handleCreateModal}
                  text="Add Reseller"
                />
              </div>
            </div>
          ) : null}
          <div className="row mt-2">
            <div className="col">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    {/* <th>Username</th> */}
                    <th>Address</th>
                    <th>Contact Details</th>
                    <th>Email</th>
                    {this.state.editPermission ||
                    this.state.deletePermission ? (
                      <th>Option</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {this.state.resellers.length > 0 ? (
                    this.state.resellers.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            {item.media && item.media.length > 0 ? (
                              <img
                                src={
                                  fileUrl +
                                  "/" +
                                  item.media[0].id +
                                  "/" +
                                  item.media[0].file_name
                                }
                                className="logoDisplay"
                              />
                            ) : null}
                          </td>
                          <td>
                            {item.user.first_name} {item.user.last_name}
                          </td>
                          {/* <td>{item.user.username}</td> */}
                          <td>{item.user.address}</td>
                          <td>{item.user.contact}</td>
                          <td>{item.user.email}</td>
                          {this.state.editPermission ||
                          this.state.deletePermission ? (
                            <td>
                              {this.state.editPermission ? (
                                <CustomButton
                                  text="Edit"
                                  onClick={() => this.handleEdit(item)}
                                ></CustomButton>
                              ) : null}
                              {this.state.deletePermission ? (
                                <CustomButton
                                  text="Delete"
                                  className="ms-2 cancelBtn"
                                  onClick={() => this.handleDelete(item)}
                                />
                              ) : null}
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={
                          this.state.editPermission ||
                          this.state.deletePermission
                            ? 7
                            : 6
                        }
                        className="text-center p-3"
                      >
                        {this.state.dataSpinner ? (
                          <>
                            <Spinner color="primary"></Spinner>
                            <br />
                            Loading Data...
                          </>
                        ) : (
                          "No Resellers added yet"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <CustomModal
            modal={this.state.createModal}
            modalBody={
              <CreateReseller
                edit={this.state.edit}
                getResellers={this.getResellers}
                handleCreateModal={this.handleCreateModal}
                selectedData={this.state.selectedData}
              />
            }
            modalHeader={this.state.edit ? "Edit Reseller" : "Add Reseller"}
            size="lg"
            toggleModal={this.handleCreateModal}
          ></CustomModal>
        </div>
      </>
    );
  }
}

export default Resellers;
