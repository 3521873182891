import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import CustomButton from "../common/CustomButton";

function AdvisorRegistrationPaymentDisplay(props) {
  return props.isSubscribed ? null : (
    <>
      <div className="payInfoBox">
        <FontAwesomeIcon icon={faInfoCircle} />
        {"  "}Pay the Registration Fee first to use the system
      </div>
      <div className="outstandingBalanceBox">
        <div className="container-fluid ps-0 pe-0">
          <div className="row">
            <div className="col-md-7">
              <div style={{ fontSize: "14px" }}>Type</div>
              <div style={{ fontSize: "18px" }}>
                <strong>Advisor Registration Charge</strong>
              </div>
            </div>
            <div className="col-md-3">
              <div style={{ fontSize: "14px" }}>Outstanding Balance</div>
              <div style={{ fontSize: "18px" }}>
                <strong>
                  {props.currency} ${props.totalFees}
                </strong>
              </div>
            </div>
            <div
              className="col-md-2"
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton onClick={props.handlePayNow} text="Pay Now" />
            </div>
          </div>
          <hr></hr>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col">
              <table className="table table-striped mb-0">
                <thead>
                  <tr>
                    <th colSpan={3}>Payment Breakdown</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Training Fees</td>
                    <td width="20px">:</td>
                    <td>
                      {props.currency} ${props.trainingFees}
                    </td>
                  </tr>
                  <tr>
                    <td>Subscription Fees ( This Month )</td>
                    <td width="20px">:</td>
                    <td>
                      {props.currency} ${props.subscriptionFees}
                    </td>
                  </tr>
                  <tr>
                    <td>Subscription Fees ( Advance )</td>
                    <td width="20px">:</td>
                    <td>
                      {props.currency} ${props.advanceSubscriptionFees}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdvisorRegistrationPaymentDisplay;
