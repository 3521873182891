import React, { Component } from "react";
import AddPaymentMethod from "./BillingMethod/AddPaymentMethod";

class IsFirstLogin extends Component {
  render() {
    return (
      <div style={{ position: "relative", height: "100%" }}>
        <div className="isFirstLoginDiv">
          <div className="payInfoBox mb-3" style={{ fontSize: "13px" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris a
            porta mi. Suspendisse potenti. Morbi mattis, elit in consequat
            volutpat, neque felis iaculis augue, quis finibus est massa ac
            lacus.
          </div>
          <div className="isFirstLoginBox">
            <h3>Add Payment Method First to continue</h3>
            <div className="mt-3 mb-3">
              <hr></hr>
            </div>
            <AddPaymentMethod
              isFirstLogin={true}
              isClient={this.props.isClient}
              isAdvisor={this.props.isAdvisor}
              getAdvisorLatestTransaction={
                this.props.getAdvisorLatestTransaction
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default IsFirstLogin;
