import { faSadTear } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Spinner } from "reactstrap";
import CustomButton from "../../common/CustomButton";
import product from "../../assets/images/download.png";
import { fileUrl } from "../../utils/Constants";

function ProductList(props) {
  return (
    <div className="productListBox">
      <strong>All Products</strong>
      <div className="productListHolder">
        {props.products.length > 0 ? (
          props.products.map((item, idx) => {
            return (
              <div className="productBox" key={idx}>
                <div className="productImage">
                  <img
                    src={
                      item.media && item.media.length > 0
                        ? fileUrl +
                          "/" +
                          item.media[0].id +
                          "/" +
                          item.media[0].file_name
                        : product
                    }
                  />
                </div>
                <div className="productTitle">
                  <strong>{item.name}</strong>
                </div>
                <div className="productDescription">{item.description}</div>

                <div className="productPrice">
                  AUD $<span>{item.base_price}</span>
                </div>

                <div className="text-end">
                  <CustomButton
                    text="Buy"
                    className="cancelBtn me-2"
                    onClick={() => props.handleSubscribeBtn(item)}
                  />
                  {/* {item.is_subscribed ? (
                    <span className="me-2" style={{ fontSize: "12px" }}>
                      Subscribed
                    </span>
                  ) : (
                    <CustomButton
                      text="Subscribe"
                      className="cancelBtn me-2"
                      onClick={() => props.handleSubscribeBtn(item)}
                    />
                  )} */}

                  <CustomButton
                    text="Details"
                    onClick={() => props.handleDetailModal(item)}
                  ></CustomButton>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center p-4" style={{ width: "100%" }}>
            {props.loader ? (
              <>
                <Spinner></Spinner>
                <br></br>Loading Data...
              </>
            ) : (
              <>
                {" "}
                <FontAwesomeIcon
                  icon={faSadTear}
                  style={{ fontSize: "100px", marginBottom: "20px" }}
                />
                <br></br>
                <h3>Sorry, No products added yet</h3>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductList;
