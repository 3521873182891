import React, { Component } from "react";
import swal from "sweetalert";
import CustomButton from "../../common/CustomButton";
import CustomFullSpinner from "../../common/CustomFullSpinner";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert, DISPLAYMESSAGE } from "../../utils/DisplayMessage";

class EditTier extends Component {
  state = {
    currency: "",
    fee: "",
    from: null,
    id: "",
    name: "",
    spinner: false,
    to: null,
  };

  componentDidMount() {
    this.config();
  }

  config = () => {
    let selectedData = this.props.selectedData;
    this.setState({
      currency: selectedData.currency,
      fee: selectedData.fee,
      from: selectedData.from,
      id: selectedData.id,
      name: selectedData.name,
      to: selectedData.to,
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  handleSave = () => {
    let param = {
      id: this.state.id,
      fee: this.state.fee,
      from: this.state.from,
      name: this.state.name,
      to: this.state.to,
    };
    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.updateTier,
        param,
        (res) => {
          if (res.status === 200) {
            this.setState({ spinner: false });
            swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
            this.props.getAllTiers();
            this.props.closeEditTier();
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  render() {
    return (
      <div className="container-fluid">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="row">
          <div className="col-md-3 mt-1">
            <strong>Tier Name</strong>
          </div>
          <div className="col">
            <input
              type="text"
              name="name"
              className="form-control"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Users</strong>
          </div>
          <div className="col">
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  name="from"
                  className="form-control"
                  value={this.state.from}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-1 mt-1">
                <strong>-</strong>
              </div>
              <div className="col">
                <input
                  type="text"
                  name="to"
                  className="form-control"
                  value={this.state.to}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3 mt-1">
            <strong>Fee</strong>
          </div>
          <div className="col">
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon1">
                AUD
              </span>
              <input
                type="text"
                name="fee"
                className="form-control"
                value={this.state.fee}
                aria-describedby="basic-addon1"
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            <CustomButton text="Save" onClick={this.handleSave} />
            <CustomButton
              text="Cancel"
              className="ms-2 cancelBtn"
              onClick={this.props.closeEditTier}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default EditTier;
