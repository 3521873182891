import React, { Component } from "react";
import swal from "sweetalert";
import CustomButton from "../../common/CustomButton";
import CustomFullSpinner from "../../common/CustomFullSpinner";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { fileUrl, URL as URLs } from "../../utils/Constants";
import { displayErrorAlert, DISPLAYMESSAGE } from "../../utils/DisplayMessage";

class CreateAdvisor extends Component {
  state = {
    address: "",
    contact: "",
    email: "",
    error: {},
    id: "",
    imagePreview: "",
    firstName: "",
    lastName: "",
    password: "",
    photo: "",
    photoFile: "",
    spinner: false,
    // username: "",
    resellerId: "",
    website: "",
    postalCode: "",
    selectedState: "",
    countries: [],
    country: "",
    cities: [],
    city: "",
    cityLoader: false,
  };

  componentDidMount() {
    if (this.props.edit) {
      this.editConfig();
    }
    this.getCountries();
  }

  editConfig = () => {
    let selectedData = this.props.selectedData;
    debugger;
    this.setState({
      firstName: selectedData.user.first_name,
      lastName: selectedData.user.last_name,
      address: selectedData.user.address,
      email: selectedData.user.email,
      contact: selectedData.user.contact,
      website: selectedData.user.website,
      postalCode: selectedData.user.postal_code,
      selectedState: selectedData.user.state,
      city: selectedData.user.city_id,
      // username: selectedData.user.username,
      // password: selectedData.password,
      id: selectedData.id,
      imagePreview:
        selectedData.media && selectedData.media.length > 0
          ? fileUrl +
            "/" +
            selectedData.media[0].id +
            "/" +
            selectedData.media[0].file_name
          : null,
    });
    let resellers = this.props.resellers;
    if (resellers.length > 0) {
      resellers.forEach((el) => {
        if (el.user_id === selectedData.parent_user_id) {
          this.setState({ resellerId: el.id });
        }
      });
    }
  };

  getCitites = () => {
    this.setState({ cityLoader: true, cities: [] }, function () {
      axiosGet(
        URLs.getCities + this.state.country,
        (res) => {
          if (res.status === 200) {
            this.setState({
              cities: res.data.data.item.cities,
              cityLoader: false,
            });
          }
        },
        (err) => {
          this.setState({ cityLoader: false });
        }
      );
    });
  };

  getCountries = () => {
    axiosGet(URLs.getCountries, (res) => {
      if (res.status === 200) {
        this.setState({ countries: res.data.data.items }, function () {
          if (this.state.countries.length > 0) {
            let countries = this.state.countries;
            countries.forEach((el) => {
              if (el.name === "Australia") {
                this.setState({ country: el.code }, function () {
                  this.getCitites();
                });
              }
            });
          }
        });
      }
    });
  };

  handleCancel = () => {
    this.setState({
      address: "",
      contact: "",
      email: "",
      error: {},
      imagePreview: "",
      photo: "",
      photoFile: "",
      firstName: "",
      lastName: "",
      password: "",
      website: "",
      selectedState: "",
      city: "",
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      this.setState(
        {
          photo: e.target.value,
          photoFile: e.target.files[0],
        },
        function () {
          let image = URL.createObjectURL(this.state.photoFile);
          this.setState({ imagePreview: image });
        }
      );
    }
  };

  handleSave = () => {
    let formData = new FormData();
    this.setState({ error: {} }, function () {
      let error = this.validateData();
      if (Object.keys(error).length !== 0) {
        this.setState({ error }, function () {
          swal("Error", "Fill all the necessary fields", "error");
        });
        return false;
      }
      let param = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        address: this.state.address,
        email: this.state.email,
        contact: this.state.contact,
        // username: this.state.username,
        password: this.state.password,
        state: this.state.selectedState,
        city_id: this.state.city,
        website: this.state.website,
        postal_code: this.state.postalCode,
      };
      if (this.props.isAdmin || this.props.isRootUser) {
        param.parent_user_id = this.state.resellerId;
      }
      formData.append("advisor", JSON.stringify(param));
      if (this.state.photoFile !== "") {
        formData.append("file", this.state.photoFile);
      }
      // else {
      //   formData.append("file", []);
      // }
      this.setState({ spinner: true }, function () {
        axiosPost(
          URLs.insertAdvisor,
          formData,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.insertSuccess, "success");
              this.props.getAdvisors();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    });
  };

  handleUpdate = () => {
    let formData = new FormData();
    let param = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      address: this.state.address,
      // email: this.state.email,
      contact: this.state.contact,
      id: this.state.id,
      state: this.state.selectedState,
      city_id: this.state.city,
      website: this.state.website,
      postal_code: this.state.postalCode,
    };
    if (this.props.isAdmin || this.props.isRootUser) {
      param.parent_user_id = this.state.resellerId;
    }
    formData.append("advisor", JSON.stringify(param));
    if (this.state.photoFile !== "") {
      formData.append("file", this.state.photoFile);
    }
    this.setState({ spinner: true }, function () {
      axiosPost(
        URLs.updateAdvisor,
        formData,
        (response) => {
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
            this.props.getAdvisors();
            this.props.handleCreateModal();
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  removeImage = () => {
    this.setState({ photo: "", imagePreview: "", photoFile: "" });
  };

  validateData = () => {
    let error = {};
    if (this.state.address === "") {
      error.address = true;
    }
    if (this.state.firstName === "") {
      error.name = true;
    }
    if (this.state.lastName === "") {
      error.lastName = true;
    }
    if (this.state.email === "") {
      error.email = true;
    }
    if (this.state.contact === "") {
      error.contact = true;
    }
    if (this.state.selectedState === "") {
      error.selectedState = true;
    }
    if (this.state.postalCode === "") {
      error.postalCode = true;
    }
    if (this.state.city === "") {
      error.city = true;
    }
    if (this.state.website === "") {
      error.website = true;
    }
    if (this.state.password === "") {
      error.password = true;
    }
    if (this.props.isAdmin || this.props.isRootUser) {
      if (this.state.resellerId === "") {
        error.resellerId = true;
      }
    }
    return error;
  };

  render() {
    return (
      <div className="container-fluid">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        {/* {this.state.imagePreview && this.state.imagePreview !== "" ? (
          <div className="row mb-2">
            <div className="col text-center">
              <div
                className="imageHolder"
                style={{ width: "70px", height: "70px" }}
              >
                <img src={this.state.imagePreview} />
              </div>
            </div>
          </div>
        ) : null} */}
        {/* <div className="row">
          <div className="col-md-1"></div>

          <div className="col-md-3 mt-1">
            <strong>Photo</strong>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col">
                {this.props.edit &&
                this.state.imagePreview &&
                this.state.photo === "" ? (
                  <input
                    className="form-control"
                    disabled
                    type="text"
                    value={this.props.selectedData.media[0].name}
                  />
                ) : (
                  <input
                    accept="image/png, image/jpeg"
                    className="form-control"
                    name="photo"
                    onChange={this.handleImageChange}
                    type="file"
                    value={this.state.photo}
                  />
                )}
              </div>
              {this.state.imagePreview && this.state.imagePreview !== "" ? (
                <div className="col-md-auto">
                  <CustomButton
                    className="cancelBtn"
                    onClick={this.removeImage}
                    text="Remove"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-md-1"></div>
        </div> */}
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>First Name</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.firstName ? "invalid" : "")
              }
              name="firstName"
              onChange={this.handleChange}
              type="text"
              value={this.state.firstName}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Surname</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.lastName ? "invalid" : "")
              }
              name="lastName"
              onChange={this.handleChange}
              type="text"
              value={this.state.lastName}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Address Line 1</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.address ? "invalid" : "")
              }
              name="address"
              onChange={this.handleChange}
              type="text"
              value={this.state.address}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>State</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " +
                (this.state.error.selectedState ? "invalid" : "")
              }
              name="selectedState"
              onChange={this.handleChange}
              type="text"
              value={this.state.selectedState}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Postal Code</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.postalCode ? "invalid" : "")
              }
              name="postalCode"
              onChange={this.handleChange}
              type="text"
              value={this.state.postalCode}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Suburb / City</strong>
          </div>
          <div className="col-md-7">
            <select
              className={
                "form-select signUpTextField " +
                (this.state.error.city ? "invalid" : "")
              }
              name="city"
              onChange={this.handleChange}
              value={this.state.city}
              placeholder="Suburb / City"
            >
              <option value="" disabled selected>
                Suburb / City
              </option>

              {this.state.cities.length > 0 ? (
                this.state.cities.map((city, idx) => {
                  return (
                    <option
                      key={idx}
                      value={city.id}
                      selected={city.id == this.state.city ? true : false}
                    >
                      {city.name}
                    </option>
                  );
                })
              ) : (
                <option disabled>
                  {this.state.cityLoader ? "Loading Data..." : "No Data"}
                </option>
              )}
            </select>
          </div>
          <div className="col-md-1"></div>
        </div>

        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Contact No</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.contact ? "invalid" : "")
              }
              name="contact"
              onChange={this.handleChange}
              type="text"
              value={this.state.contact}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Website</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.website ? "invalid" : "")
              }
              name="website"
              onChange={this.handleChange}
              type="text"
              value={this.state.website}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        {this.props.isAdmin || this.props.isRootUser ? (
          <div className="row mt-2">
            <div className="col-md-1"></div>
            <div className="col-md-3 mt-1">
              <strong>Reseller</strong>
            </div>
            <div className="col-md-7">
              <select
                name="resellerId"
                value={this.state.resellerId}
                className={
                  "form-select " +
                  (this.state.error.resellerId ? "invalid" : "")
                }
                onChange={this.handleChange}
              >
                <option value="" disabled selected>
                  Choose Reseller
                </option>
                {this.props.resellers.map((reseller, idx) => {
                  return (
                    <option value={reseller.id} key={idx}>
                      {reseller.user.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-1"></div>
          </div>
        ) : null}

        {/* <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Username</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.username ? "invalid" : "")
              }
              name="username"
              onChange={this.handleChange}
              type="text"
              value={this.state.username}
            />
          </div>
          <div className="col-md-1"></div>
        </div> */}
        {this.props.edit ? null : (
          <>
            <hr></hr>
            <div className="row mt-2">
              <div className="col-md-1"></div>
              <div className="col-md-3 mt-1">
                <strong>Email</strong>
              </div>
              <div className="col-md-7">
                <input
                  className={
                    "form-control " + (this.state.error.email ? "invalid" : "")
                  }
                  name="email"
                  onChange={this.handleChange}
                  type="email"
                  value={this.state.email}
                />
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row mt-2">
              <div className="col-md-1"></div>
              <div className="col-md-3 mt-1">
                <strong>Password</strong>
              </div>
              <div className="col-md-7">
                <input
                  className={
                    "form-control " +
                    (this.state.error.password ? "invalid" : "")
                  }
                  name="password"
                  onChange={this.handleChange}
                  type="password"
                  value={this.state.password}
                />
              </div>
              <div className="col-md-1"></div>
            </div>
          </>
        )}
        <div className="row mt-3">
          <div className="col text-end">
            {this.props.edit ? (
              <CustomButton
                className="me-2"
                onClick={this.handleUpdate}
                text="Update"
              />
            ) : (
              <CustomButton
                className="me-2"
                onClick={this.handleSave}
                text="Add"
              />
            )}

            <CustomButton
              className="cancelBtn"
              onClick={this.handleCancel}
              text="Cancel"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CreateAdvisor;
