import React, { useState } from "react";
import { Spinner } from "reactstrap";
import CustomButton from "../../common/CustomButton";
import CustomModal from "../../common/CustomModal";
import { fileUrl } from "../../utils/Constants";

function ProductListForResellers(props) {
  const [data, setData] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [preview, setPreview] = useState("");

  const closeViewModal = () => {
    setViewModal(false);
  };

  const previewImage = (idx) => {
    let previewData = fileUrl + "/" + data[idx].id + "/" + data[idx].file_name;
    setPreview(previewData);
  };

  const viewImages = (images) => {
    setViewModal(true);
    let previewData = fileUrl + "/" + images[0].id + "/" + images[0].file_name;
    setPreview(previewData);
    setData(images);
  };

  const imageModalBody = () => {
    return (
      <div className="container-fluid">
        {data.length > 0 ? (
          <>
            <div className="productPreview">
              <img src={preview} />
            </div>
            <div className="productThumbnailHolder">
              {data.map((image, idx) => {
                return (
                  <div
                    className="productThumbnail"
                    key={idx}
                    onClick={() => previewImage(idx)}
                  >
                    <img
                      src={fileUrl + "/" + image.id + "/" + image.file_name}
                    />
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          "No Images"
        )}
      </div>
    );
  };

  return (
    <div>
      <table className="table table-striped mb-0">
        <thead>
          <tr>
            <th>Product Name</th>
            <th width="50%">Description</th>
            <th>Amount</th>
            <th>Payment Type</th>
            <th>Images</th>
          </tr>
        </thead>
        <tbody>
          {props.products.length > 0 ? (
            props.products.map((prod, idx) => {
              return (
                <tr key={idx}>
                  <td>{prod.name}</td>
                  <td>{prod.description}</td>
                  <td>AUD ${prod.base_price}</td>
                  {/* {prod.currency
                      ? `${prod.currency} ${prod.unitAmount}`
                      : `${prod.price.currency} ${prod.price.unit_amount}`}
                  </td> */}
                  <td>{prod.type}</td>
                  <td>
                    {prod.media && prod.media.length > 0 ? (
                      <CustomButton
                        text="View Images"
                        onClick={() => viewImages(prod.media)}
                      />
                    ) : (
                      "No Images"
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={5} className="text-center">
                {props.loader ? (
                  <>
                    <Spinner></Spinner>
                    <br></br>Loading Data...
                  </>
                ) : (
                  "No Products added yet"
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <CustomModal
        modal={viewModal}
        toggleModal={closeViewModal}
        modalHeader="Images"
        modalBody={imageModalBody()}
        size="lg"
      ></CustomModal>
    </div>
  );
}

export default ProductListForResellers;
