import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { axiosGet } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
class ApiKeys extends Component {
  state = {
    clientKey: "",
    secretKey: "",
    cKeyCopy: false,
    sKeyCopy: false,
  };

  componentDidMount() {
    this.getKeys();
  }

  getKeys = () => {
    axiosGet(URL.getMyInformation, (res) => {
      if (res.status === 200) {
        this.setState({
          clientKey: res.data.client_key,
          secretKey: res.data.secret_key,
        });
      }
    });
  };

  copyClientKey = () => {
    this.setState({ cKeyCopy: true, sKeyCopy: false }, function () {
      setTimeout(() => {
        this.setState({ cKeyCopy: false });
      }, 500);
    });
  };

  copySecretKey = () => {
    this.setState({ cKeyCopy: false, sKeyCopy: true }, function () {
      setTimeout(() => {
        this.setState({ sKeyCopy: false });
      }, 500);
    });
  };

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col">
              <div className="titleHeaderStyle">API Keys</div>
            </div>
          </div>
        </div>
        <div className="container mt-2">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col">
              <div style={{ border: "1px solid #ccc", padding: "15px" }}>
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-2 mt-1">
                    <strong>Client-key</strong>
                  </div>
                  <div className="col-md-7">
                    <input
                      type="text"
                      value={this.state.clientKey}
                      disabled
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    {this.state.cKeyCopy ? (
                      <buttton
                        className="btn btn-secondary"
                        type="button"
                        disabled
                      >
                        Copied
                      </buttton>
                    ) : (
                      <CopyToClipboard
                        text={this.state.clientKey}
                        onCopy={this.copyClientKey}
                      >
                        <button className="btn btn-primary">Copy</button>
                      </CopyToClipboard>
                    )}
                  </div>
                  <div className="col-md-1"></div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-1"></div>
                  <div className="col-md-2 mt-1">
                    <strong>Secret-key</strong>
                  </div>
                  <div className="col-md-7">
                    <input
                      type="text"
                      value={this.state.secretKey}
                      className="form-control"
                      disabled
                    />
                  </div>
                  <div className="col">
                    {this.state.sKeyCopy ? (
                      <buttton
                        className="btn btn-secondary"
                        type="button"
                        disabled
                      >
                        Copied
                      </buttton>
                    ) : (
                      <CopyToClipboard
                        text={this.state.secretKey}
                        onCopy={this.copySecretKey}
                      >
                        <button className="btn btn-primary">Copy</button>
                      </CopyToClipboard>
                    )}
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </>
    );
  }
}

export default ApiKeys;
