import React, { Component } from "react";
import CustomButton from "../../common/CustomButton";

class EditInformationModal extends Component {
  state = {
    address: "",
    billingInformation: {
      name: "",
      email: "",
      phone: "",
      address: "",
    },
    contact: "",
    email: "",
    imagePreview: "",
    logo: "",
    logoFile: "",
    name: "",
    photo: "",
  };

  componentDidMount() {
    if (this.props.edit) {
      this.editConfig();
    }
  }

  editConfig = () => {
    this.setState({
      address: this.props.address,
      billingInformation: this.props.hasBillingDetails
        ? this.props.billingInformation
        : {},
      email: this.props.email,
      name: this.props.name,
      contact: this.props.contact,
      imagePreview: this.props.photo ? this.props.photo : "",
    });
  };

  handleBillingInformationDataChange = (e) => {
    let { name, value } = e.target;
    let billingInformation = this.state.billingInformation;
    billingInformation[name] = value;
    this.setState({ billingInformation });
  };

  handleCancel = () => {
    this.setState({
      address: "",
      billingInformation: {
        name: "",
        email: "",
        phone: "",
        address: "",
      },
      contact: "",
      email: "",
      imagePreview: "",
      logo: "",
      logoFile: "",
      name: "",
      photo: "",
    });
  };

  handleChange = (e) => {
    let name = e.target.name,
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  };

  handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      this.setState(
        {
          logo: e.target.value,
          logoFile: e.target.files[0],
        },
        function () {
          let image = URL.createObjectURL(this.state.logoFile);
          this.setState({ imagePreview: image });
        }
      );
    }
  };

  handleSave = () => {
    alert("I am saved");
  };

  removeImage = () => {
    this.setState({ logo: "", imagePreview: "", logoFile: "" });
  };

  render() {
    return (
      <div className="container-fluid">
        <div
          style={{ maxHeight: "69vh", overflowX: "hidden", overflowY: "auto" }}
        >
          {this.props.isAdmin ? null : (
            <>
              {this.state.imagePreview !== "" ? (
                <div className="row mt-2 mb-2">
                  <div className="col text-center">
                    <div className="imageHolder">
                      <img src={this.state.imagePreview} />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-md-2 mt-1">
                  <strong>Photo</strong>
                </div>
                <div className="col-md-10">
                  <div className="row">
                    <div className="col">
                      {this.props.edit &&
                      this.state.imagePreview &&
                      this.state.logo === "" ? (
                        <input
                          className="form-control"
                          disabled
                          type="text"
                          value={this.props.imageMedia.file_name}
                        />
                      ) : (
                        <input
                          accept="image/png, image/jpeg"
                          className="form-control"
                          name="logo"
                          onChange={this.handleImageChange}
                          type="file"
                          value={this.state.logo}
                        />
                      )}
                    </div>
                    {this.state.imagePreview !== "" ? (
                      <div className="col-md-auto">
                        <CustomButton
                          className="cancelBtn"
                          onClick={this.removeImage}
                          text="Remove Image"
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="row mt-2">
            <div className={this.props.isAdmin ? "col" : "col-md-6"}>
              <div className="row">
                <div className="col-md-4 mt-1">
                  <strong>Name</strong>
                </div>
                <div className="col">
                  <input
                    className="form-control"
                    name="name"
                    onChange={this.handleChange}
                    type="text"
                    value={this.state.name}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4 mt-1">
                  <strong>Email</strong>
                </div>
                <div className="col">
                  <input
                    className="form-control"
                    name="email"
                    onChange={this.handleChange}
                    type="email"
                    value={this.state.email}
                  />
                </div>
              </div>
            </div>
            {this.props.isAdmin ? null : (
              <div
                className="col-md-6"
                style={{ borderLeft: "1px solid #ccc" }}
              >
                <div className="row mt-2">
                  <div className="col-md-4 mt-1">
                    <strong>Address</strong>
                  </div>
                  <div className="col">
                    <input
                      className="form-control"
                      name="address"
                      onChange={this.handleChange}
                      type="text"
                      value={this.state.address}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4 mt-1">
                    <strong>Contact</strong>
                  </div>
                  <div className="col">
                    <input
                      className="form-control"
                      name="contact"
                      onChange={this.handleChange}
                      type="text"
                      value={this.state.contact}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {this.props.hasBillingDetails && !this.props.isAdmin ? (
            <div className="row mt-2">
              <div className="col-md-1"></div>
              <div className="col">
                <div style={{ border: "1px solid #ccc" }}>
                  <div
                    className="titleHeaderStyle"
                    style={{ fontSize: "17px" }}
                  >
                    <strong>Billing Information</strong>
                  </div>
                  <div className="container">
                    <div className="row mt-2">
                      <div className="col-md-3 mt-2">
                        <strong>Name</strong>
                      </div>
                      <div className="col">
                        <input
                          className="form-control"
                          name="name"
                          onChange={this.handleBillingInformationDataChange}
                          type="text"
                          value={this.state.billingInformation.name}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3 mt-2">
                        <strong>Address</strong>
                      </div>
                      <div className="col">
                        <input
                          className="form-control"
                          name="address"
                          onChange={this.handleBillingInformationDataChange}
                          type="text"
                          value={this.state.billingInformation.address}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3 mt-2">
                        <strong>Contact</strong>
                      </div>
                      <div className="col">
                        <input
                          className="form-control"
                          name="contact"
                          onChange={this.handleBillingInformationDataChange}
                          type="text"
                          value={this.state.billingInformation.contact}
                        />
                      </div>
                    </div>
                    <div className="row mt-2 mb-2">
                      <div className="col-md-3 mt-2">
                        <strong>Email</strong>
                      </div>
                      <div className="col">
                        <input
                          className="form-control"
                          name="email"
                          onChange={this.handleBillingInformationDataChange}
                          type="text"
                          value={this.state.billingInformation.email}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          ) : null}
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            <CustomButton
              className="me-2"
              onClick={this.handleSave}
              text="Update"
            ></CustomButton>
            <CustomButton
              className="cancelBtn"
              onClick={this.handleCancel}
              text="Cancel"
            ></CustomButton>
          </div>
        </div>
      </div>
    );
  }
}

export default EditInformationModal;
