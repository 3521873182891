import React, { Component } from "react";
import CustomButton from "../../common/CustomButton";
import CustomModal from "../../common/CustomModal";
import EditTier from "./EditTiers";
import { checkRolePermissions } from "../../utils/Helper";
import { moduleName, URL } from "../../utils/Constants";
import { axiosDelete, axiosGet } from "../../utils/AxiosApi";
import { displayErrorAlert, DISPLAYMESSAGE } from "../../utils/DisplayMessage";
import { Spinner } from "reactstrap";
import CreateTier from "./CreateTier";
import swal from "sweetalert";

class EndUserSubscriptionFee extends Component {
  state = {
    createModal: false,
    deletePermission: false,
    editModal: false,
    editPermission: false,
    spinner: false,
    selectedData: {},
    tiers: [],
    tiersCopy: [],
  };

  closeEditTier = () => {
    this.setState({
      editModal: !this.state.editModal,
    });
  };

  componentDidMount() {
    this.permissions();
    this.getAllTiers();
  }

  getAllTiers = () => {
    this.setState({ spinner: true }, function () {
      axiosGet(
        URL.getResellersTiers,
        (res) => {
          if (res.status === 200) {
            this.setState({ tiers: res.data.data.items, spinner: false });
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleCreateModal = () => {
    this.setState({ createModal: !this.state.createModal });
  };

  handleDelete = (tier) => {
    axiosDelete(URL.deleteTier + tier.id, (res) => {
      if (res.status === 200) {
        swal("Success", DISPLAYMESSAGE.deleteSuccess, "success");
        this.getAllTiers();
      }
    });
  };

  handleEditTier = (tier) => {
    this.setState({
      editModal: !this.state.editModal,
      selectedData: tier,
    });
  };

  handleSave = () => {
    this.setState({ tiers: this.state.tiersCopy });
    this.closeEditTier();
  };

  handleTierChange = (e, idx) => {
    let tiersCopy = [...this.state.tiersCopy],
      { name, value } = e.target;
    tiersCopy[idx][name] = value;
    this.setState({ tiersCopy });
  };

  permissions = () => {
    this.setState({
      editPermission: checkRolePermissions("update", moduleName.tier),
      deletePermission: checkRolePermissions("delete", moduleName.tier),
    });
  };

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col">
              <div className="titleHeaderStyle">End User Subscription Fee</div>
            </div>
          </div>
        </div>
        <div className="container mt-2">
          {checkRolePermissions("create", moduleName.tier) ? (
            <div className="row mt-2">
              <div className="col text-end">
                <CustomButton
                  text="Create Tiers"
                  onClick={this.handleCreateModal}
                />
              </div>
            </div>
          ) : null}

          <div className="row mt-2">
            <div className="col">
              <table className="table table-striped table-bordered mb-0 text-center">
                <thead>
                  <tr>
                    <th>Tier Name</th>
                    <th>Total number of Users</th>
                    <th>Fee per User</th>
                    {this.state.editPermission ||
                    this.state.deletePermission ? (
                      <th>Action</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {this.state.tiers.length > 0 ? (
                    this.state.tiers.map((tier, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{tier.name}</td>
                          <td>
                            {tier.to.includes("+")
                              ? tier.to
                              : `${tier.from} - ${tier.to}`}
                          </td>
                          <td>
                            {tier.currency} {tier.fee}
                          </td>
                          {this.state.editPermission ||
                          this.state.deletePermission ? (
                            <td>
                              {this.state.editPermission ? (
                                <CustomButton
                                  text="Edit"
                                  onClick={() => this.handleEditTier(tier)}
                                ></CustomButton>
                              ) : null}
                              {this.state.deletePermission ? (
                                <CustomButton
                                  text="Delete"
                                  className="cancelBtn ms-2"
                                  onClick={() => this.handleDelete(tier)}
                                ></CustomButton>
                              ) : null}
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={
                          this.state.editPermission ||
                          this.state.deletePermission
                            ? 4
                            : 3
                        }
                      >
                        {this.state.spinner ? (
                          <>
                            <Spinner></Spinner>
                            <br></br>Loading Data...
                          </>
                        ) : (
                          "No Tier Data Assigned"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <CustomModal
          modal={this.state.editModal}
          modalBody={
            <EditTier
              closeEditTier={this.closeEditTier}
              selectedData={this.state.selectedData}
              getAllTiers={this.getAllTiers}
            />
          }
          modalHeader="Edit Tiers"
          toggleModal={this.closeEditTier}
        ></CustomModal>
        <CustomModal
          modal={this.state.createModal}
          modalBody={
            <CreateTier
              getAllTiers={this.getAllTiers}
              handleCreateModal={this.handleCreateModal}
            />
          }
          modalHeader="Create Tier"
          size="lg"
          toggleModal={this.handleCreateModal}
        />
      </>
    );
  }
}

export default EndUserSubscriptionFee;
