import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { compose } from "redux";

import CustomButton from "../../common/CustomButton";
import TextField from "../../common/TextField";
import { axiosPost } from "../../utils/AxiosApi";
import { cryptoSecretKey, loginUrl } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import { WithRouter } from "../../utils/WithRouter";
import CustomFullSpinner from "../../common/CustomFullSpinner";

var CryptoJS = require("crypto-js");

/**
 * This is a Login Page for the application
 * @author Sandeep Shakya
 *
 * @param  {string}  username Username of the user
 * @param  {string}  password Password of the user
 */
// const navigate = useNavigate();
class LoginPage extends Component {
  state = {
    capsLockStatus: false,
    inputType: "password",
    password: "",
    username: "",
    spinner: false,
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleInputTypeChange = () => {
    if (this.state.inputType === "password") {
      this.setState({ inputType: "text" });
    } else {
      this.setState({ inputType: "password" });
    }
  };

  handleKeydown = (e) => {
    if (e.key === "Enter") {
      this.handleLogin();
    }
    if (e.getModifierState("CapsLock")) {
      this.setState({ capsLockStatus: true });
    } else {
      this.setState({ capsLockStatus: false });
    }
  };

  handleLogin = () => {
    if (this.state.username !== "" && this.state.password !== "") {
      let param = {
        email: this.state.username,
        password: this.state.password,
      };
      this.setState({ spinner: true }, function () {
        axiosPost(
          loginUrl,
          param,
          (response) => {
            if (response.status === 200) {
              let datum = response.data.data;
              localStorage.setItem("token", datum.token);

              var permissionEncrypt = CryptoJS.AES.encrypt(
                JSON.stringify(datum.permissions),
                cryptoSecretKey
              ).toString();
              localStorage.setItem("permissions", permissionEncrypt);

              var rolesEncrypt = CryptoJS.AES.encrypt(
                JSON.stringify(datum.roles),
                cryptoSecretKey
              ).toString();
              localStorage.setItem("roles", rolesEncrypt);
              localStorage.setItem(
                "requirePaymentMethod",
                datum.require_payment_method
              );
              this.props.history(`${process.env.PUBLIC_URL}/dashboard`);
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    } else {
      swal("Error", "Please enter Email and Password both", "error");
    }
  };

  render() {
    let props = this.props;
    return (
      <div className="container-fluid ps-0 pe-0 loginPageContainer">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="loginBoxContainer">
          <div style={{ fontSize: "45px" }} className="companyHeader">
            <span>ODEE</span> Payments System
          </div>
          <div className="loginBoxHolder">
            <div>
              <h3>Login</h3>
            </div>
            <div className="inputTypeHolder">
              <TextField
                className="loginTextField"
                handleChange={this.handleChange}
                icon={<FontAwesomeIcon icon={faUser} />}
                inputType="text"
                name="username"
                value={this.state.username}
              ></TextField>
            </div>
            <div className="inputTypeHolder">
              <TextField
                className="loginTextField"
                handleChange={this.handleChange}
                handleInputTypeChange={this.handleInputTypeChange}
                icon={<FontAwesomeIcon icon={faKey} />}
                inputType={this.state.inputType}
                name="password"
                onKeyDown={this.handleKeydown}
                value={this.state.password}
              ></TextField>
            </div>
            {this.state.capsLockStatus ? (
              <div style={{ marginTop: "-20px", textAlign: "center" }}>
                Caps Lock is On
              </div>
            ) : null}
            <div className="text-end mt-2">
              <CustomButton
                onClick={this.handleLogin}
                style={{ width: "100%" }}
                text="LOGIN"
              ></CustomButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {};

const mapActionsToProps = {};

export default compose(
  WithRouter,
  connect(mapStateToProps, mapActionsToProps)
)(LoginPage);
