import React from "react";

function CustomButton({ className, onClick, style, text }) {
  return (
    <button
      className={"loginBtn " + (className ? className : "")}
      onClick={onClick}
      style={style}
    >
      {text}
    </button>
  );
}

export default CustomButton;
