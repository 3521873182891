import { axiosGet } from "../utils/AxiosApi";
import { URL } from "../utils/Constants";

// Actions
const isFirstLoginLOAD = "isFirst_Login_LOAD";
const isSubscribedLOAD = "isSubscribed_LOAD";
const loaderLOAD = "loader_LOAD";
const permissionsLOAD = "PERMISSIONS_LOAD";

/**
 * This ducks file cotains all redux functions for Login Page.
 * @author Sandeep Shakya
 *
 */

export default function reducer(
  state = {
    isFirstLogin: false,
    isSubscribed: true,
    loader: false,
    permissions: [],
  },
  action
) {
  const newState = { ...state };
  switch (action.type) {
    case permissionsLOAD:
      newState.permissions = action.permissions;
      return newState;
    case isFirstLoginLOAD:
      newState.isFirstLogin = action.isFirstLogin;
      return newState;
    case isSubscribedLOAD:
      newState.isSubscribed = action.isSubscribed;
      return newState;
    case loaderLOAD:
      newState.loader = action.loader;
      return newState;
    default:
      return newState;
  }
}

// Action Creators
export function loadIsFirstLogin(isFirstLogin, isSubscribed) {
  return { type: isFirstLoginLOAD, isFirstLogin };
}

export function loadIsSubscribed(isSubscribed) {
  return { type: isSubscribedLOAD, isSubscribed };
}

export function loadLoader(loader) {
  return { type: loaderLOAD, loader };
}

export function loadPermissions(permissions) {
  return { type: permissionsLOAD, permissions };
}

//Dispatchers
export function getAdvisorStatus() {
  return (dispatch) => {
    dispatch(loadLoader(true));
    axiosGet(
      URL.getSystemStatus,
      (response) => {
        if (response.status === 200) {
          dispatch(loadIsFirstLogin(response.data.data.is_first_login));
          dispatch(loadIsSubscribed(response.data.data.has_paid));
          dispatch(loadLoader(false));
        }
      },
      (err) => {
        dispatch(loadLoader(false));
      }
    );
  };
}

export function setIsFirstLogin(isFirstLogin) {
  return (dispatch) => {
    dispatch(loadIsFirstLogin(isFirstLogin));
  };
}

export function setIsSubscribed(isSubscribed) {
  return (dispatch) => {
    dispatch(loadIsSubscribed(isSubscribed));
  };
}

export function setPermissions(permissions) {
  return (dispatch) => {
    dispatch(loadPermissions(permissions));
  };
}
