import React, { Component } from "react";
import swal from "sweetalert";
import CustomButton from "../../common/CustomButton";
import CustomFullSpinner from "../../common/CustomFullSpinner";
import { axiosPost } from "../../utils/AxiosApi";
import { moduleName, URL } from "../../utils/Constants";
import { displayErrorAlert, DISPLAYMESSAGE } from "../../utils/DisplayMessage";
import { checkRolePermissions } from "../../utils/Helper";

class PermissionsModal extends Component {
  state = {
    allPermissions: [],
    edit: false,
    spinner: false,
  };

  componentDidMount() {
    this.config();
  }

  config = () => {
    let allPermissions = [...this.props.permissions];
    let rolePermissions = [...this.props.rolePermissions];
    if (rolePermissions && rolePermissions.length > 0) {
      allPermissions.forEach((el) => {
        el.action.forEach((ac) => {
          let check = rolePermissions.findIndex((el) => el.id === ac.id);
          if (check !== -1) {
            ac.checked = true;
          } else {
            ac.checked = false;
          }
        });
      });
      this.setState({ allPermissions, edit: false });
    }
  };

  handleActionCheckAll = (e, permissionIdx) => {
    let allPermissions = [...this.state.allPermissions];
    if (e.target.checked) {
      allPermissions[permissionIdx].action.forEach((el) => {
        el.checked = true;
      });
    } else {
      allPermissions[permissionIdx].action.forEach((el) => {
        el.checked = false;
      });
    }
    this.setState({ allPermissions });
  };

  handleCancel = () => {
    this.config();
  };

  handleCheckbox = (e, permissionIdx, actionIdx) => {
    let allPermissions = [...this.state.allPermissions];
    allPermissions[permissionIdx].action[actionIdx].checked = e.target.checked;
    this.setState({ allPermissions });
  };

  handleEdit = () => {
    this.setState({ edit: true });
  };

  handleSave = () => {
    let allPermissions = [...this.state.allPermissions],
      permissions = [];

    if (allPermissions.length > 0) {
      allPermissions.forEach((el) => {
        el.action.forEach((ac) => {
          if (ac.checked) {
            permissions.push(ac.id);
          }
        });
      });
    }

    let param = {
      role_id: this.props.selectedRoleId,
      permissions: permissions,
    };

    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.assignPermissionToRole,
        param,
        (res) => {
          if (res.status === 200) {
            swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
            this.setState({ spinner: false });
            this.props.closePermissionModal();
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  render() {
    return (
      <div className="container-fluid">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        {checkRolePermissions("update", moduleName.userLevel) ? (
          <div className="row">
            <div className="col text-end">
              {this.state.edit ? (
                <>
                  <CustomButton text="Save" onClick={this.handleSave} />
                  <CustomButton
                    text="Cancel"
                    className="cancelBtn ms-2"
                    onClick={this.handleCancel}
                  />
                </>
              ) : (
                <CustomButton
                  text="Edit Permissions"
                  onClick={this.handleEdit}
                />
              )}
            </div>
          </div>
        ) : null}
        <div className="row mt-3">
          <div className="col">
            <div className="text-center permissionHeader">
              <strong>Permissions</strong>
            </div>
            <div
              className="userLevelPermissionBox"
              style={{ maxHeight: "60.6vh" }}
            >
              {this.state.allPermissions.map((permission, idx) => {
                return (
                  <table className="table table-bordered " key={idx}>
                    <thead className="table-primary">
                      <tr>
                        <th colSpan={permission.action.length - 1}>
                          {permission.moduleName}
                        </th>
                        <th className="text-center">
                          {this.state.edit ? (
                            <>
                              <input
                                id={permission.moduleName + idx}
                                type="checkbox"
                                className="form-check-input"
                                onChange={(e) =>
                                  this.handleActionCheckAll(e, idx)
                                }
                              ></input>
                              <label
                                htmlFor={permission.moduleName + idx}
                                className="ms-2"
                              >
                                Check All
                              </label>
                            </>
                          ) : null}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      <tr>
                        {permission.action.map((act, aIdx) => {
                          return (
                            <td key={aIdx}>
                              {this.state.edit ? (
                                <>
                                  <input
                                    id={permission.moduleName + aIdx}
                                    type="checkbox"
                                    className="form-check-input"
                                    name="checkbox"
                                    checked={act.checked}
                                    onChange={(e) =>
                                      this.handleCheckbox(e, idx, aIdx)
                                    }
                                    style={{ fontSize: "15px" }}
                                  ></input>
                                  <label
                                    htmlFor={permission.moduleName + aIdx}
                                    className="ms-2"
                                    style={{ fontSize: "15px" }}
                                  >
                                    {act.name}
                                  </label>
                                </>
                              ) : (
                                <>
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={act.checked}
                                    disabled={!this.state.edit}
                                    style={{ fontSize: "15px" }}
                                  ></input>
                                  <span
                                    className="ms-2"
                                    style={{ fontSize: "15px" }}
                                  >
                                    <strong>{act.name}</strong>
                                  </span>
                                </>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PermissionsModal;
