import { cryptoSecretKey } from "./Constants";
var CryptoJS = require("crypto-js");

export const checkRolePermissions = (action, moduleName) => {
  let encryptedData = localStorage.getItem("permissions");
  let checkData = `${action}|${moduleName}`;
  let checkIndex = -1;
  if (encryptedData) {
    var bytes = CryptoJS.AES.decrypt(encryptedData, cryptoSecretKey);
    var permissions = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log(permissions);
    if (permissions.length > 0) {
      checkIndex = permissions.findIndex(
        (permission) => permission.name === checkData
      );
      if (checkIndex !== -1) return true;
      else return false;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const checkUserRoles = (userRole) => {
  let encryptedData = localStorage.getItem("roles");
  if (encryptedData) {
    var bytes = CryptoJS.AES.decrypt(encryptedData, cryptoSecretKey);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    var check = decryptedData.findIndex((el) => el === userRole);
    if (check !== -1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
