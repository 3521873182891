import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import CustomButton from "../../common/CustomButton";
import CustomFullSpinner from "../../common/CustomFullSpinner";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";

class AssignRoles extends Component {
  state = {
    roles: [],
    userId: "",
    usersRole: [],
    spinner: false,
    loader: false,
  };

  getUsersRole = () => {
    this.setState({ loader: true }, function () {
      axiosGet(
        URL.getUsersRole + this.state.userId,
        (res) => {
          if (res.status === 200) {
            this.setState({
              usersRole: res.data.data.item.roles,
              loader: false,
            });
          }
        },
        (err) => {
          this.setState({ loader: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleCancel = () => {
    this.setState({ userId: "", roles: [], usersRole: [] });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({ [name]: value }, function () {
      if (name === "userId") {
        this.getUsersRole();
      }
    });
  };

  handleMultipleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    this.setState({
      roles: value,
    });
  };

  handleSave = () => {
    let roles = [...this.state.roles],
      user_id = this.state.userId;
    if (user_id === "") {
      swal("Error", "User is not selected", "error");
      return false;
    }
    if (roles.length > 0) {
      roles.forEach((el) => {
        el = parseInt(el);
      });
      let param = {
        user_id: user_id,
        roles: roles,
      };
      this.setState({ spinner: true }, function () {
        axiosPost(
          URL.assignRoleToUsers,
          param,
          (res) => {
            if (res.status === 200) {
              swal(
                "Success",
                "Roles successfully assigned to users",
                "success"
              );
              this.setState({ spinner: false, roles: [] });
              this.getUsersRole();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    } else {
      swal("Error", "Select roles first", "error");
      return false;
    }
  };

  revokeRoleFromUser = (role) => {
    let param = {
      user_id: this.state.userId,
      role_id: [role.id],
    };
    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.revokeRolesFromUser,
        param,
        (res) => {
          if (res.status === 200) {
            swal("Success", "Role successfully unassigned", "success");
            this.setState({ spinner: false });
            this.getUsersRole();
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  render() {
    return (
      <div className="container-fluid">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="row">
          <div className="col">
            <strong>Select User</strong>
            <select
              name="userId"
              className="form-select"
              value={this.state.userId}
              onChange={this.handleChange}
            >
              <option value="" disabled selected>
                Choose User
              </option>
              {this.props.users.length > 0
                ? this.props.users.map((user, idx) => {
                    return (
                      <option value={user.id} key={idx}>
                        {user.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
          <div className="col">
            <strong>Select Roles</strong>
            <select
              name="roles"
              value={this.state.roles}
              className="form-select"
              onChange={this.handleMultipleChange}
              multiple
            >
              <option value="" disabled selected>
                Choose Roles
              </option>
              {this.props.userRoles.length > 0
                ? this.props.userRoles.map((role, idx) => {
                    return (
                      <option value={role.id} key={idx}>
                        {role.name}
                      </option>
                    );
                  })
                : null}
            </select>
            <span style={{ fontSize: "10px" }}>
              <strong>
                <i>* Hold Ctrl or Shift for selecting multiple</i>
              </strong>
            </span>
          </div>
        </div>
        {this.state.usersRole.length > 0 ? (
          <div className="row mt-2">
            <div className="col">
              <table className="table table-striped table-bordered text-center">
                <thead>
                  <tr>
                    <th>Role Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.usersRole.length > 0
                    ? this.state.usersRole.map((role, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{role.name}</td>
                            <td>
                              <CustomButton
                                text="UnAssign Role"
                                onClick={() => this.revokeRoleFromUser(role)}
                              />
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        ) : this.state.loader ? (
          <div className="row">
            <div className="col p-3 text-center">
              <Spinner></Spinner>
              <br></br>Loading Data...
            </div>
          </div>
        ) : null}

        <div className="row mt-3">
          <div className="col text-end">
            <CustomButton
              text="Assign"
              onClick={this.handleSave}
            ></CustomButton>
            <CustomButton
              text="Cancel"
              className="ms-2 cancelBtn"
              onClick={this.handleCancel}
            ></CustomButton>
          </div>
        </div>
      </div>
    );
  }
}

export default AssignRoles;
