import React, { Component } from "react";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButton from "../../common/CustomButton";
import CustomFullSpinner from "../../common/CustomFullSpinner";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import swal from "sweetalert";
import { displayErrorAlert, DISPLAYMESSAGE } from "../../utils/DisplayMessage";

class CreateTier extends Component {
  state = {
    spinner: false,
    tiers: [
      {
        currency: "AUD",
        fee: "",
        from: null,
        name: "",
        to: null,
      },
    ],
  };

  addTier = () => {
    let tiers = [...this.state.tiers];
    tiers.push({
      currency: "AUD",
      fee: "",
      from: null,
      name: "",
      to: null,
    });
    this.setState({ tiers });
  };

  handleCancel = () => {
    this.setState({
      tiers: [
        {
          currency: "AUD",
          fee: "",
          from: null,
          name: "",
          to: null,
        },
      ],
    });
  };

  handleChange = (e, idx) => {
    let tiers = [...this.state.tiers];
    let name = e.target.name;
    let value = e.target.value;
    tiers[idx][name] = value;
    this.setState({ tiers });
  };

  handleSave = () => {
    let param = [...this.state.tiers];
    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.insertResellersTiers,
        param,
        (response) => {
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.insertSuccess, "success");
            this.setState({ spinner: false });
            this.props.getAllTiers();
            this.props.handleCreateModal();
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  removeTier = (idx) => {
    let tiers = [...this.state.tiers];
    tiers.splice(idx, 1);
    this.setState({ tiers });
  };

  render() {
    return (
      <div className="container-fluid">
        {this.state.spinner ? (
          <CustomFullSpinner text="Procesing..."></CustomFullSpinner>
        ) : null}
        <div className="row">
          <div
            className="col"
            style={{
              maxHeight: "69.5vh",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {this.state.tiers.map((tier, idx) => {
              return (
                <div className="row mb-4" key={idx}>
                  <div className="col-md-10">
                    <table className="table text-center table-bordered mb-0">
                      <thead className="table-primary">
                        <tr>
                          <th>Tier Name</th>
                          <th>Number of Users</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              value={tier.name}
                              onChange={(e) => this.handleChange(e, idx)}
                            />
                          </td>
                          <td>
                            <div className="row">
                              <div className="col">
                                <input
                                  type="text"
                                  name="from"
                                  className="form-control"
                                  value={tier.from}
                                  placeholder="From"
                                  onChange={(e) => this.handleChange(e, idx)}
                                />
                              </div>
                              <div className="col-md-1">-</div>
                              <div className="col">
                                <input
                                  type="text"
                                  name="to"
                                  className="form-control"
                                  placeholder="To"
                                  value={tier.to}
                                  onChange={(e) => this.handleChange(e, idx)}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <span class="input-group">
                              <span
                                class="input-group-text"
                                id="basic-addon1"
                                style={{ fontSize: "12px" }}
                              >
                                {tier.currency}
                              </span>
                              <input
                                type="text"
                                name="fee"
                                className="form-control"
                                value={tier.fee}
                                aria-describedby="basic-addon1"
                                onChange={(e) => this.handleChange(e, idx)}
                              />
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="col-md-2"
                    style={{
                      borderLeft: "1px solid #ccc",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    {idx === 0 ? null : (
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        className="removeBtn"
                        onClick={() => this.removeTier(idx)}
                      ></FontAwesomeIcon>
                    )}
                    {idx === this.state.tiers.length - 1 ? (
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        className="ms-2"
                        onClick={this.addTier}
                      ></FontAwesomeIcon>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            <CustomButton text="Save" onClick={this.handleSave} />
            <CustomButton
              text="Cancel"
              className="cancelBtn ms-2"
              onClick={this.handleCancel}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CreateTier;
