import React, { Component } from "react";
import swal from "sweetalert";
import CustomButton from "../../common/CustomButton";
import CustomFullSpinner from "../../common/CustomFullSpinner";
import { axiosPost } from "../../utils/AxiosApi";
import { fileUrl, URL as URLs } from "../../utils/Constants";
import { displayErrorAlert, DISPLAYMESSAGE } from "../../utils/DisplayMessage";

class CreateRootUser extends Component {
  state = {
    address: "",
    contact: "",
    email: "",
    error: {},
    id: "",
    imagePreview: "",
    firstName: "",
    lastName: "",
    password: "",
    photo: "",
    photoFile: "",
    spinner: false,
    username: "",
  };

  componentDidMount() {
    if (this.props.edit) {
      this.editConfig();
    }
  }

  editConfig = () => {
    let selectedData = this.props.selectedData;
    this.setState({
      firstName: selectedData.user.first_name,
      lastName: selectedData.user.last_name,
      address: selectedData.user.address,
      // email: selectedData.user.email,
      contact: selectedData.user.contact,
      id: selectedData.id,
      imagePreview:
        selectedData.media && selectedData.media.length > 0
          ? fileUrl +
            "/" +
            selectedData.media[0].id +
            "/" +
            selectedData.media[0].file_name
          : null,
    });
  };

  handleCancel = () => {
    this.setState({
      address: "",
      contact: "",
      email: "",
      error: {},
      imagePreview: "",
      firstName: "",
      lastName: "",
      password: "",
      photo: "",
      photoFile: "",
      username: "",
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      this.setState(
        {
          photo: e.target.value,
          photoFile: e.target.files[0],
        },
        function () {
          let image = URL.createObjectURL(this.state.photoFile);
          this.setState({ imagePreview: image });
        }
      );
    }
  };

  handleSave = () => {
    let formData = new FormData();
    this.setState({ error: {} }, function () {
      let error = this.validateData();
      if (Object.keys(error).length !== 0) {
        this.setState({ error }, function () {
          swal("Error", "Fill all the necessary fields", "error");
        });
        return false;
      }
      let param = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        address: this.state.address,
        email: this.state.email,
        contact: this.state.contact,
        // username: this.state.username,
        password: this.state.password,
      };
      formData.append("rootUser", JSON.stringify(param));
      // if (this.state.photoFile !== "") {
      //   formData.append("file", this.state.photoFile);
      // }
      //  else {
      //   formData.append("file", []);
      // }
      this.setState({ spinner: true }, function () {
        axiosPost(
          URLs.rootUsers,
          formData,
          (response) => {
            if (response.status === 200) {
              swal("Success", DISPLAYMESSAGE.insertSuccess, "success");
              this.props.getRootUsers();
              this.props.handleCreateModal();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    });
  };

  handleUpdate = () => {
    let formData = new FormData();
    let param = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      address: this.state.address,
      contact: this.state.contact,
      id: this.state.id,
    };
    formData.append("rootUser", JSON.stringify(param));
    // if (this.state.photoFile !== "") {
    //   formData.append("file", this.state.photoFile);
    // }
    this.setState({ spinner: true }, function () {
      axiosPost(
        URLs.updateRootUser,
        formData,
        (response) => {
          if (response.status === 200) {
            swal("Success", DISPLAYMESSAGE.updateSuccess, "success");
            this.props.getRootUsers();
            this.props.handleCreateModal();
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  removeImage = () => {
    this.setState({ photo: "", imagePreview: "", photoFile: "" });
  };

  validateData = () => {
    let error = {};
    if (this.state.address === "") {
      error.address = true;
    }
    if (this.state.firstName === "") {
      error.name = true;
    }
    if (this.state.lastName === "") {
      error.name = true;
    }
    if (this.state.email === "") {
      error.email = true;
    }
    if (this.state.contact === "") {
      error.contact = true;
    }
    // if (this.state.username === "") {
    //   error.username = true;
    // }
    if (this.state.password === "") {
      error.password = true;
    }
    return error;
  };

  render() {
    return (
      <div className="container-fluid">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        {/* {this.state.imagePreview && this.state.imagePreview !== "" ? (
          <div className="row mb-2">
            <div className="col text-center">
              <div
                className="imageHolder"
                style={{ width: "70px", height: "70px" }}
              >
                <img src={this.state.imagePreview} />
              </div>
            </div>
          </div>
        ) : null} */}
        {/* <div className="row">
          <div className="col-md-1"></div>

          <div className="col-md-3 mt-1">
            <strong>Photo</strong>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col">
                {this.props.edit &&
                this.state.imagePreview &&
                this.state.photo === "" ? (
                  <input
                    className="form-control"
                    disabled
                    type="text"
                    value={this.props.selectedData.media[0].name}
                  />
                ) : (
                  <input
                    accept="image/png, image/jpeg"
                    className="form-control"
                    name="photo"
                    onChange={this.handleImageChange}
                    type="file"
                    value={this.state.photo}
                  />
                )}
              </div>
              {this.state.imagePreview && this.state.imagePreview !== "" ? (
                <div className="col-md-auto">
                  <CustomButton
                    className="cancelBtn"
                    onClick={this.removeImage}
                    text="Remove"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-md-1"></div>
        </div> */}
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>First Name</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.firstName ? "invalid" : "")
              }
              name="firstName"
              onChange={this.handleChange}
              type="text"
              value={this.state.firstName}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Surname</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.lastName ? "invalid" : "")
              }
              name="lastName"
              onChange={this.handleChange}
              type="text"
              value={this.state.lastName}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Address</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.address ? "invalid" : "")
              }
              name="address"
              onChange={this.handleChange}
              type="text"
              value={this.state.address}
            />
          </div>
          <div className="col-md-1"></div>
        </div>

        <div className="row mt-2">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Contact No</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.contact ? "invalid" : "")
              }
              name="contact"
              onChange={this.handleChange}
              type="text"
              value={this.state.contact}
            />
          </div>
          <div className="col-md-1"></div>
        </div>
        <hr></hr>

        {/* <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-1">
            <strong>Username</strong>
          </div>
          <div className="col-md-7">
            <input
              className={
                "form-control " + (this.state.error.username ? "invalid" : "")
              }
              name="username"
              onChange={this.handleChange}
              type="text"
              value={this.state.username}
            />
          </div>
          <div className="col-md-1"></div>
        </div> */}
        {this.props.edit ? null : (
          <>
            <div className="row mt-2">
              <div className="col-md-1"></div>
              <div className="col-md-3 mt-1">
                <strong>Email</strong>
              </div>
              <div className="col-md-7">
                <input
                  className={
                    "form-control " + (this.state.error.email ? "invalid" : "")
                  }
                  name="email"
                  onChange={this.handleChange}
                  type="email"
                  value={this.state.email}
                />
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row mt-2">
              <div className="col-md-1"></div>
              <div className="col-md-3 mt-1">
                <strong>Password</strong>
              </div>
              <div className="col-md-7">
                <input
                  className={
                    "form-control " +
                    (this.state.error.password ? "invalid" : "")
                  }
                  name="password"
                  onChange={this.handleChange}
                  type="password"
                  value={this.state.password}
                />
              </div>
              <div className="col-md-1"></div>
            </div>
          </>
        )}
        <div className="row mt-3">
          <div className="col text-end">
            {this.props.edit ? (
              <CustomButton
                className="me-2"
                onClick={this.handleUpdate}
                text="Update"
              />
            ) : (
              <CustomButton
                className="me-2"
                onClick={this.handleSave}
                text="Add"
              />
            )}

            <CustomButton
              className="cancelBtn"
              onClick={this.handleCancel}
              text="Cancel"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CreateRootUser;
