import "./App.css";
import Routes from "./routes/Routes";
import React from "react";
import "./assets/scss/main.scss";

function App(props) {
  return (
    <React.Fragment>
      {props.username
        ? `${props.username} is an employee in Techart Trekkies`
        : ""}
      <Routes />
    </React.Fragment>
  );
}

export default App;
