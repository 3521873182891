import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";

import CustomButton from "../common/CustomButton";
import { axiosGet, axiosPost } from "../utils/AxiosApi";
import { cardType, URL } from "../utils/Constants";
import { displayErrorAlert } from "../utils/DisplayMessage";
import { getAdvisorStatus } from "../ducks/LoginDucks";
import CustomFullSpinner from "../common/CustomFullSpinner";
import { Spinner } from "reactstrap";

class PayNow extends Component {
  state = {
    amount: "",
    currency: "",
    loader: false,
    paymentMethods: [],
    selectedPaymentMethod: "",
    spinner: false,
  };

  componentDidMount() {
    this.getPaymentMethods();
    this.config();
  }

  config = () => {
    let datum = this.props.paymentData;
    this.setState({
      amount: datum,
      currency: this.props.currency,
    });
  };

  getPaymentMethods = () => {
    this.setState({ loader: true }, function () {
      axiosGet(
        URL.getBillingMethod,
        (response) => {
          if (response.status === 200) {
            let datum = response.data.data.items,
              selectedPaymentMethod = "";
            if (datum.length > 0) {
              datum.forEach((el) => {
                if (el.is_default) {
                  selectedPaymentMethod = el.id;
                }
              });
            }
            this.setState({
              paymentMethods: response.data.data.items,
              selectedPaymentMethod,
              loader: false,
            });
          }
        },
        (err) => {
          this.setState({ loader: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  pay = () => {
    let param = {
      // initial_amount: this.state.amount,
      // currency: this.state.currency,
      payment_method_id: this.state.selectedPaymentMethod,
    };
    this.setState({ spinner: true }, function () {
      axiosPost(
        URL.payAdvisorRegistrationBill,
        param,
        (response) => {
          if (response.status === 200) {
            swal("Success", "Registration Fee successfully paid", "success");
            this.props.getAdvisorStatus();
            this.props.getAdvisorBill();
            this.props.getAdvisorLatestTransaction();
            this.props.handlePayNow();
          }
        },
        (err) => {
          this.setState({ spinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  render() {
    return (
      <div className="container-fluid">
        {this.state.spinner ? (
          <CustomFullSpinner text="Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="row">
          <div className="col-md-4 mt-1">Payment Method</div>
          <div className="col text-center">
            {this.state.loader ? (
              <Spinner />
            ) : (
              <select
                className="form-select"
                name="selectedPaymentMethod"
                onChange={this.handleChange}
                value={this.state.selectedPaymentMethod}
              >
                <option value="" disabled selected>
                  Choose Payment Method
                </option>
                {this.state.paymentMethods.length > 0
                  ? this.state.paymentMethods.map((item, idx) => {
                      return (
                        <option value={item.id} key={idx}>
                          {item.card.brand === cardType.visa
                            ? "VISA"
                            : "Mastercard"}{" "}
                          - {item.card.last4}
                        </option>
                      );
                    })
                  : null}
              </select>
            )}
          </div>
        </div>

        <div className="row">
          <div
            className="col text-center"
            style={{
              background: "#003d4f",
              color: "white",
              marginTop: "20px",
              padding: "10px",
            }}
          >
            <strong>Total Amount: </strong>
            {this.state.currency} ${this.state.amount}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-end">
            {this.state.loader ? null : (
              <CustomButton text="Pay" onClick={this.pay} />
            )}
            <CustomButton
              onClick={this.props.handlePayNow}
              className="ms-2 cancelBtn"
              text="Cancel"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isFirstLogin: state.login.isFirstLogin,
  isSubscribed: state.login.isSubscribed,
});

const mapActionsToProps = {
  getAdvisorStatus,
};

export default connect(mapStateToProps, mapActionsToProps)(PayNow);
